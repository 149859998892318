import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utility/api";
import { fetchAll } from "./patientSlice";



export const getStatusDescription = createAsyncThunk(
    "getStatusDescription",
    async () => {
        try {
            const response = await api.get("/api/Patient/GetStatusDescription");


            return response.data?.data;
        } catch (error) {
            console.error("Error fetching messages:", error);
            throw error;
        }
    }
);

export const getRoleNames = createAsyncThunk(
    "getRoleNames",
    async () => {
        try {
            const response = await api.get("/api/Patient/GetRoleNames");
            return response.data?.data;
        } catch (error) {
            console.error("Error fetching messages:", error);
            throw error;
        }
    }
);

export const updatePendingStatus = createAsyncThunk(
    "updatePendingStatus",
    async ({ data }, thunkApi) => {
        try {
            const response = await api.post("/api/Patient/AddUpdatePatientSubStatus", data);
            await thunkApi.dispatch(fetchAll(data.created_by));
            return response;
        } catch (error) {
            console.error("Error fetching messages:", error);
            throw error;
        }
    }
);


export const taskDetails = createAsyncThunk("taskDetails", async (id) => {
    try {
        if (id) {
            const responseid = await api.get(`api/Patient/task/${id}`);
            const taskdata = responseid.data;
            return taskdata.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching patient data:", error);
        throw error;
    }
});




const taskSlice = createSlice({
    name: "updatetask",
    initialState: {
        loading: false,
        assignRoleByUser: [],

        isTaskOpen: false,
        taskRowData: [],
        statusDescription: [],
        roleNames: [],
        pendingStatusModal: {
            isOpen: false,
            data: undefined,
        },
        modalLoading: false,
        taskItem: [],

    },
    reducers: {

        taskToggle(state, action) {
            state.pendingStatusModal.isOpen = action.payload?.isOpen;
            state.pendingStatusModal.data = action.payload?.rowData;

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getStatusDescription.fulfilled, (state, action) => {
            state.statusDescription = action.payload;
        });
        builder.addCase(getRoleNames.fulfilled, (state, action) => {
            state.roleNames = action.payload;
        });
        builder.addCase(taskDetails.pending, (state) => {
            state.modalLoading = true;
        })
        builder.addCase(taskDetails.fulfilled, (state, action) => {
            state.taskItem = action.payload;
            state.modalLoading = false;
        })
        builder.addCase(taskDetails.rejected, (state, action) => {
            state.modalLoading = false;
        })

    },
});

export const { taskToggle } = taskSlice.actions;
export default taskSlice.reducer;
