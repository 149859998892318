// UserContext.js
import React, { createContext, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ userEmail, children }) => (
    <UserContext.Provider value={userEmail}>
        {children}
    </UserContext.Provider>
);

export const useUserEmail = () => useContext(UserContext);
