import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useLocation } from "react-router-dom";
import DateFormatter from "./DateFormatter";
import { useEffect } from "react";

const CustomDataTable = ({
  data,
  filters,
  loading,
  selectedCustomers,
  onSelectionChange,
  chatModal,
  idTemplate,
  mrnBodyTemplate,
  firstNameBodyTemplate,
  customHeader,
  documentModal,
  displayflag,
  pendingStatusBody,
}) => {
  const pageOptions = [5, 10, 15, 25, 50, 75, 100];

  const location = useLocation();
  const path = location.pathname;


  return (
    <DataTable
      scrollable scrollHeight="645px"
      value={data}
      paginator
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rows={15}
      rowsPerPageOptions={pageOptions}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      filters={filters}
      loading={loading}
      selection={selectedCustomers}
      onSelectionChange={onSelectionChange}
      globalFilterFields={["first_name", "last_name", "full_name"]}
      emptyMessage="No Records found."
      className="custom-scroll"


    >
      <Column field="mrn" sortable header="MRN#" body={mrnBodyTemplate} />
      <Column field="first_name" sortable header="First Name" body={firstNameBodyTemplate} />
      <Column field="last_name" sortable header="Last Name" />
      <Column field="is_flag" sortable header="Flag" body={displayflag} />
      <Column field="Message" header="Chat" body={chatModal} modal={chatModal} />
      <Column field="completed_task_count" header="Task" className="task" sortable body={idTemplate} modal={idTemplate} />
      {/* <Column header="Next Actions By" field="pending_sub_status" body={pendingStatusBody} /> */}
      <Column header="Next Action By" field="next_action_by" sortable />
      <Column
        field="most_recent_update"
        sortable
        body={(rowData) => <DateFormatter date={rowData.most_recent_update} />}
        header="Most Recent Update"
        filterField="most_recent_update"
        dataType="date"
      />


      <Column
        field="reference_date"
        sortable
        body={(rowData) => <DateFormatter date={rowData.reference_date} />}
        header="Ref Date"
        filterField="reference_date"
        dataType="date"
      />

      {path === "/no-go" ? <Column field="nogo_reason" sortable header="NoGo Reason" /> : null}

      {path === "/no-go" ? null : (
        <Column
          field="nursing_visite_date"
          sortable
          body={(rowData) => <DateFormatter date={rowData.nursing_visite_date} />}
          header="Nursing Visit"
          filterField="nursing_visite_date"
          dataType="date"
        />
      )}

      <Column field="pat_category" sortable header="Category" />
      <Column field="site" sortable header="Site" />
      <Column field="assigned_to" sortable header="Sales Representative" />
      <Column field="prescriber_name" sortable header="Prescriber" />

      <Column header="Documents" body={documentModal} modal={documentModal} />
      <Column header={customHeader} />
    </DataTable>
  );
};

export default CustomDataTable;