import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utility/api";

export const getCategory = createAsyncThunk(
    'addtopatientchart/getCategory',  // Action type
    (thunkAPI) => {
        return api.get('/api/FaxModule/getDocumentCatogory') // Directly returning the promise
            .then(response => response.data)  // Resolving the promise to return data
            .catch(error => {
                console.error("Error fetching category data:", error);
                return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message); // Handle the rejection
            });
    }
);




export const getDescription = createAsyncThunk(
    'addtopatientchart/getDescription',  // Action type
    (thunkAPI) => {
        return api.get('/api/FaxModule/getDocumentDescription') // Directly returning the promise
            .then(response => response.data)  // Resolving the promise to return data
            .catch(error => {
                console.error("Error fetching Description data:", error);
                return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message); // Handle the rejection
            });
    }
);




const addtopatient  = createSlice({
    name: "addtopatientchart",
    initialState: {
        loading: false,
       category:[],
       description:[]
    },
    reducers: {
    
    },
    extraReducers: (builder) => {
        builder.addCase(getCategory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.category = action.payload.data;  // Store the category data
        });
        builder.addCase(getCategory.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getDescription.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDescription.fulfilled, (state, action) => {
            state.loading = false;
            state.description = action.payload.data;  // Store the description data
        });
        builder.addCase(getDescription.rejected, (state) => {
            state.loading = false;
        });

       
    },
});


export default addtopatient.reducer;