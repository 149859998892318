import { Button } from 'primereact/button';
import React from 'react'
import { useLocation } from 'react-router-dom';
import AccessDenied from "../../assets/images/access.svg";


export const NotFound = () => {
    const powerApp = process.env.REACT_APP_POWERAPP_LINK;

    const location = useLocation();
    const from = (location.state?.from);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-full not-found">
            <img className="mb-4" src={AccessDenied} alt="Access Denied" />
            <h4 className="font-bold">Access Denied</h4>
            <div>You don't have permissions to access this page.</div>
            <div>
                Contact an administrator to get permissions or go to the home page and
                browse other pages.
            </div>
            <a href={`${powerApp}&toscr=home&fromscr=${from}`}>
                <Button
                    className="text-color mt-3 not-found-btn"
                    outlined
                    icon="pi pi-home"
                    label="Go to Home"
                />
            </a>
        </div>
    )
}
