import axios from "axios";
import { acquireToken } from "../../ConfigLogin";

// axios.defaults.baseURL = `https://pendingpatient.azurewebsites.net/`;
axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
// axios.defaults.baseURL = `https://localhost:7161/`;

axios.interceptors.request.use(async function (config) {
  const token = await acquireToken();
  config.headers = { 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${token}` };
  return config;
});

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log("Logging unexpected error :::", error);
  }
  return Promise.reject(error);
});

export default axios;
