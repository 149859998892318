import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../utility/api";





export const AddNewCustomFilter = createAsyncThunk("advancedFilter/AddNewCustomFilter", async (filterData) => {
  try {
      const response = await api.post(
          `/api/ArManager/AddNewCustomFilter`,
          filterData, // Add data to the request
          {
              headers: {
                  'Content-Type': 'application/json'
              }
          }
      );
      return response.data;
  } catch (error) {
      console.error("Error adding new custom filter:", error);
      throw error;
  }
});



// Initial state
const initialState = {
  filterData: null, 
  loading: false,
  error: null,
};

// Create slice with `extraReducers` to handle async thunk
const advancedFilterSlice = createSlice({
  name: "advancedFilter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddNewCustomFilter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddNewCustomFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.filterData = action.payload;
      })
      .addCase(AddNewCustomFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the reducer to add it to the store
export default advancedFilterSlice.reducer;
