import React from "react";
import PatientList from "../patient/patientsList";

const Nogos = ({ name, userEmail }) => {
  // debugger;
  return (
    <>
      <PatientList name={name} userEmail={userEmail} />
    </>
  );
};

export default Nogos;
