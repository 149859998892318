import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from 'primereact/dialog';
import success from "../../assets/images/success.svg";
import { reviewToggle, updateReferralStatus } from "../../redux/referralsSlice";
import { Spinner } from "reactstrap";

export default function ReviewStatusModal({ userEmail }) {
    const dispatch = useDispatch();
    const { patientMNR, isReview, isconfirmLoading } = useSelector((state) => state.referral);

    const lastItem = patientMNR?.data?.length ? patientMNR.data[patientMNR.data.length - 1] : null;

    const toggle = () => {
        dispatch(reviewToggle({ isOpen: false }));
    };

    const handleDelete = async () => {
        try {
            await dispatch(
                updateReferralStatus({
                    email: userEmail,
                    status: "Review",
                    referral_id: lastItem?.referral_id,

                })
            );
            toggle();

        } catch (error) {
            console.error(error);
        }
    }


    return (
        <Dialog
            visible={isReview}
            onHide={toggle}
            className="delete-modal"
            modal
            blockScroll
            draggable={false}

        >
            <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={success} alt="cancel" width="100px" height="100px" className="mb-3" />
                <h6>Are you sure you want to Change Review Status?</h6>
            </div>
            <div className="d-flex mt-2 pt-3 mt-3 justify-content-center">
                <button type="button" className="custom-button me-2" onClick={toggle}>
                    Cancel
                </button>
                <button type="submit" disabled={isconfirmLoading} className="d-flex align-items-center common-btn" onClick={handleDelete}>
                    Confirm
                    {isconfirmLoading && <Spinner />}
                </button>
            </div>

        </Dialog>
    );
}
