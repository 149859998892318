import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Spinner, Button, Form } from 'reactstrap';

import { Chips } from 'primereact/chips';
import { InputText } from 'primereact/inputtext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useDispatch, useSelector } from 'react-redux';
import { emailsend, toggleEmailModal } from '../../redux/expiringauthSlice';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



function EmailSendModal({ userEmail, prescriberName, mrn, patientid, medicineauthorization, prescriberemail, namePopup, lastName, prescriber, dob, expDate, order, diagnosis }) {


    const { isEmailOpen, modalLoading, loading } = useSelector(state => state.expiringAuth);
    const [emailCC, setEmailCC] = useState([]);

    const [emailTo, setEmailTo] = useState([]);
    const [emailToError, setEmailToError] = useState('');
    const [emailCCError, setEmailCCError] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [editorData, setEditorData] = useState('');



    const dispatch = useDispatch();

    useEffect(() => {

        if (prescriberemail) {
            setEmailTo([prescriberemail]);
        } else {
            setEmailTo(null);
        }
        setEmailCC([]);
        setEmailSubject(`Encrypt Expiring Authorization for ${namePopup} ${lastName}`);
        setEditorData(`
          <div class='content'>
            Hello ${prescriber},<br/>
            <br/>
            We have an .........<br/>
            <br/>
            <b>Patient Name:</b> ${namePopup} ${lastName}<br/>
            <b>DOB:</b> ${dob}<br/>
            <b>Exp Date:</b>  ${expDate}<br/>
            <b>Order:</b>  ${order}<br/>
            <b>Diagnosis:</b>  ${diagnosis}<br/>     
            <br/>
            Thank you for your prompt attention to this matter.<br/>
            <br/>
            Best regards,<br/>
            Team Infucare Rx<br/>
          </div>
        `);
    }, [isEmailOpen, prescriberemail, namePopup, prescriber, dob, expDate, order, diagnosis]);


    const toggleChat = (rowData) => {
        dispatch(toggleEmailModal({ isEmailOpen: !isEmailOpen, rowData: rowData }));
    };

    const isValidEmail = (email) => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    }

    const validateEmails = (emails) => {
        if (!Array.isArray(emails)) return false;
        return emails.every(email => email !== 'No recipients' && isValidEmail(email));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (emailTo === null) {
            setEmailToError("Please enter a email address");
            return
        }
        else {
            setEmailToError('');
        }


        if (!validateEmails(emailTo) || !validateEmails(emailCC)) {
            setEmailToError('Invalid To email address');

            return;
        }
        if (!validateEmails(emailCC) || !validateEmails(emailTo)) {
            setEmailCCError('Invalid email address');
            return;
        }

        const mrnString = mrn ? mrn.toString() : '';
        const medicineauthorizationstr = medicineauthorization ? medicineauthorization.toString() : '';
        const emailToString = emailTo.length > 0 ? emailTo.join(';') : '';
        const emailCCString = emailCC.length > 0 ? emailCC.join(';') : '';
        try {
            await dispatch(
                emailsend({
                    mrn: mrnString,
                    emailIds: emailToString,
                    ccEmailIds: emailCCString,
                    subject: emailSubject,
                    body: editorData,
                    medicine_authorization_id: medicineauthorizationstr,
                    patient_id: patientid,
                    sentBy: userEmail
                })
            );

        } catch (error) {
            console.error(error);
        }
    }

    const handleChangeEmailTo = (e) => {
        const value = e.target.value;
        setEmailTo(value);
        if (!validateEmails(value)) {
            setEmailToError('Invalid email address');
        } else {
            setEmailToError('');
        }
    };

    const handleChangeEmailCC = (e) => {
        const value = e.target.value;

        setEmailCC(value);
        if (!validateEmails(value)) {
            setEmailCCError('Invalid CC email address');

        } else {

            setEmailCCError('');
        }
    };

    return (
        <Modal
            backdrop={"static"}
            className="chat-modal"
            isOpen={isEmailOpen}
            toggle={toggleChat}
            centered
        >
            <ModalHeader toggle={toggleChat} className="w-100 align-items-start pb-2">Email To {prescriberName} </ModalHeader>
            <ModalBody>
                <div className="custom-form email-form">
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-2">
                            <Col md="1">
                                <label htmlFor="to">To</label>
                            </Col>
                            <Col md="11">
                                <div className="custom-chips">
                                    <Chips value={emailTo} onChange={handleChangeEmailTo} className="form-control" />
                                    {emailToError && <div className="error text-danger">{emailToError}</div>}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md="1">
                                <label htmlFor="cc">CC</label>
                            </Col>
                            <Col md="11">

                                <div className="custom-chips">
                                    <Chips value={emailCC} onChange={handleChangeEmailCC} className="form-control" />
                                    {emailCCError && <div className="error text-danger">{emailCCError}</div>}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md="1">
                                <label htmlFor="subject">Subject</label>
                            </Col>
                            <Col md="11">
                                <InputText
                                    value={emailSubject}
                                    onChange={(e) => setEmailSubject(e.target.value)}
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                        <CKEditor
                            editor={ClassicEditor}
                            data={editorData}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setEditorData(data);
                            }}
                        />

                        <Button
                            type="submit"
                            disabled={modalLoading}
                            className="common-btn mt-3"
                        >
                            Send
                            {modalLoading && (
                                <Spinner className="ms-2" size="sm" color="light" />
                            )}
                        </Button>
                    </Form>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default EmailSendModal;
