import React from "react";
import { MultiSelect } from "primereact/multiselect";

const FilterDropdown = ({ value, options, onChange, placeholder, className }) => {
  const sortedOptions = options.slice().sort();
  return (
    <MultiSelect
      value={value}
      options={sortedOptions}
      onChange={onChange}
      placeholder={placeholder}
      className={`form-control ${className ? className : ""}`}
      optionLabel="label"
      // display={false}
      maxSelectedLabels={1}
      // showSelectAll={false}
      filter
      filterMatchMode="contains"
    />
  );
};

export default FilterDropdown;
