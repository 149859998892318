import React, { useEffect } from "react";
import "../../assets/css/sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import newstart from "../../assets/images/new_start.png";
import view from "../../assets/images/view.png";
import powerapps from "../../assets/images/house.svg";
import crm from "../../assets/images/crm.svg";
import sales from "../../assets/images/sales.svg";
import calender from "../../assets/images/date-expired-icon.svg";
import newrefral from "../../assets/images/newrefral.svg";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import { getAssignRoleByUser } from "../../redux/permissionSlice";

function Sidebar({ userEmail }) {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { assignRoleByUser } = useSelector((state) => state.permissions)




  useEffect(() => {
    dispatch(getAssignRoleByUser(userEmail))
  }, [userEmail])

  useMsalAuthentication(InteractionType.Redirect);

  const powerappUrl = process.env.REACT_APP_POWERAPP_URL;

  const isActive = (path) => {
    const fullPath = `${path}`;
    const decodedPathname = decodeURIComponent(location.pathname);
    return decodedPathname + location.search === fullPath;
  };
  const sidebarItems = [
    {
      to: "/",
      icon: newstart,
      label: "New Starts",
      page: "New Starts",
      section: "All Section"
    },
    {
      to: "/expiring_auth",
      icon: calender,
      label: "Expiring Authorizations",
      page: "Expiring Authorizations",
      section: "All Section"
    },
    {
      to: "/no-go",
      icon: view,
      label: "No-Go's",
      page: "No Go",
      section: "All Section"
    },
    {
      to: `${process.env.REACT_APP_CRM_LINK}`,
      icon: crm,
      label: "CRM",
      page: "CRM",
      section: "All Section"
    },
    {
      to: `${process.env.REACT_APP_SALES_LINK}`,
      icon: sales,
      label: "Sales",
      page: "Sales",
      section: "All Section"
    },
    {
      to: "/new-referrals",
      icon: newrefral,
      label: "New Referrals",
      page: "New Referrals",
      section: "All Section"
    },
  ];

  const hasAnyPermission = sidebarItems.some(item =>
    assignRoleByUser?.data?.find(state => state.page === item.page && state.read === "Y")
  );

  useEffect(() => {
    if (!hasAnyPermission) {
      navigate("/unauthorized")
    }
  }, [hasAnyPermission])

  return (
    <>
      <div className="shape sidebar-shape d-lg-inline-block d-none">
        <ul className="menu-list">
          <Link
            to={powerappUrl}
            className={isActive(powerappUrl) ? "active" : ""}
          >
            <div className="img-active">
              <img src={powerapps} alt="Patient" />
            </div>
            <span>Power Apps</span>
          </Link>

          <Link
            to="/unauthorized"
            className={isActive("/unauthorized") ? "active" : ""}
          >
          </Link>



          {sidebarItems?.map((item) => {
            const isPageExist = assignRoleByUser.data?.find((state) => state.page === item.page);
            if (isPageExist?.read === "Y") {

              return (
                <Link
                  to={item.to}
                  key={item.label}
                  className={isActive(item.to) ? "active" : ""}
                >
                  <div className="img-active">
                    <img
                      className={item.icon === sales ? "" : "custom-width-height"}
                      src={item?.icon}
                      alt={item?.label}
                    />
                  </div>
                  <span>{item?.label}</span>
                </Link>
              )
            }
          })}
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
