import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Modal, ModalBody, ModalHeader, Toast } from "reactstrap";
import Avatar from "@mui/material/Avatar";
import "../../assets/css/chat.css";
import { Tooltip, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  chatfileDownload,
  fetchAll,
  messageList as fetchMessages,
  messageList2,
  toggleChatModal,
  updateChatModalData,
  uploadFileForChat,
} from "../../redux/patientSlice";
import { InputTextarea } from "primereact/inputtextarea";
import { Divider } from "primereact/divider";
import { useMsal } from "@azure/msal-react";
import { Download } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { AddNote } from "./AddNote";
import { addAssignPtToggle, addNoteToggle } from "../../redux/addNoteSlice";
import note from '../../assets/images/note1.svg'
import { useForm } from "react-hook-form";
import { Tooltip as Tool } from 'primereact/tooltip';
import { AssignPtChart } from "./AssignPtChart";
import documenticon from '../../assets/images/document-icon.svg'

import { Dropdown } from 'primereact/dropdown'; // Import the Dropdown component
import { Button } from 'primereact/button'; // Import the Button component
import { getCategory, getDescription } from "../../redux/addtopatientchartslice";



const ChatModal = ({
  position,
  currentPatient,
  mrn,
  userName,
  isReadMessage,
  userEmail,
  lastName,
  firstName,

}) => {
  const { chatModalData, messageList, modalLoading, isChatModalOpen, rowData } =
    useSelector((state) => state.patient);

  const { category, description } = useSelector((state) => state.addtopatientchart)




  const dispatch = useDispatch();
  const tooltipRef = useRef(null);

  const location = useLocation();
  const path = location.pathname;
  const [showInput, setShowInput] = useState(true);

  const [messages, setMessages] = useState([...messageList]);
  const [chatMessagesWithContentdata, setChatMessagesWithContentdata] =
    useState([]);
  const [isScrollToBottom, setIsScrollToBottom] = useState(true);
  const [isNewMessageSent, setisNewMessageSent] = useState(false);

  const messagesEndRef = useRef();
  const messagesUnReadRef = useRef();
  const containerRef = useRef();
  const lastReadDividerRef = useRef();
  const fileInputRef = useRef(null);
  const [name, setName] = useState("");

  // display dialog____________________________________________________

  const [displayDialog, setDisplayDialog] = useState(false);
  const [hoverAttach, setHoverAttach] = useState(false);
  const [hoverClose, setHoverClose] = useState(false);

  const handleHover = (type, isHovered) => {
    if (type === 'attach') {
      setHoverAttach(isHovered);
    } else if (type === 'close') {
      setHoverClose(isHovered);
    }
  };



  const categoryOptions = [
    { label: 'Category 1', value: '1' },
    { label: 'Category 2', value: '2' },
    { label: 'Category 3', value: '3' }
  ];

  const descriptionOptions = [
    { label: 'Description 1', value: '1' },
    { label: 'Description 2', value: '2' },
    { label: 'Description 3', value: '3' }
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(null);

  // Open the dialog
  const showDialog = () => {
    setDisplayDialog(true);
    dispatch(getCategory())
    dispatch(getDescription())
  };


  // Close the dialog
  const closeDialog = () => {
    setDisplayDialog(false);
  };

  // Handle the "Attach" button action
  const handleAttach = () => {
    // Logic to attach to patient chart, e.g., API call
    closeDialog();
  };



  // display dialog   endd         ____________________________________________________

  const { register, handleSubmit, setValue } = useForm();
  const [Filename, setFilename] = useState('');




  const vmaxlen = 2000; // Maximum length of the output text

  const [vstxt, setVstxt] = useState(''); // Accumulated text

  const [isListening, setIsListening] = useState(false); // Listening state
  const vfinalRef = useRef(null); // Ref for the output textarea

  const vsr = useRef(null); // Ref for SpeechRecognition instance


  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (window.SpeechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition ||
        window.oSpeechRecognition)
    ) {
      const SpeechRecognition =
        window.SpeechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition ||
        window.oSpeechRecognition;

      vsr.current = new SpeechRecognition();
      vsr.current.interimResults = true; // Enable interim results
      vsr.current.continuous = true; // Enable continuous recognition
      vsr.current.lang = 'en-US'; // Set language to English

      vsr.current.onresult = (e) => {
        let finalTranscript = '';
        let interimTranscript = '';

        for (let i = e.resultIndex; i < e.results.length; i++) {
          if (e.results[i].isFinal) {
            finalTranscript += e.results[i][0].transcript; // Append final transcript
          } else {
            interimTranscript += e.results[i][0].transcript; // Append interim transcript
          }
        }

        // Update the state and the textarea with final and interim text

        setVstxt((prev) => prev + finalTranscript); // Accumulate final text
        vfinalRef.current.value = vstxt + interimTranscript; // Show interim text

        if (vfinalRef.current.value.length > vmaxlen) {
          vfinalRef.current.value = vfinalRef.current.value.substring(0, vmaxlen);
        }
      };


      vsr.current.onend = () => {
        // If still listening, restart
        if (isListening) {
          setTimeout(() => {
            vsr.current.start();
          }, 100); // Adjust the delay as needed (100ms is often sufficient)
        }
      };

      vsr.current.onerror = (event) => {
        console.error("Speech recognition error: ", event.error);
      };
    } else {
      alert('Speech recognition not available');
    }
  }, []); // Run only once

  const startListening = () => {
    if (!isListening) {
      setIsListening(true); // Set listening state to true
      vsr.current.start(); // Start recognition
    } else {
      console.log("Already listening, ignoring the start command.");
    } // Start recognition
  };

  const stopListening = () => {
    setIsListening(false); // Set listening state to false
    vsr.current.stop(); // Stop recognition completely
    resetText()
  };

  const stopListeningOnHide = (rowData) => {
    stopListening()
    toggleChat(rowData); // Toggle the chat visibility (close the dialog)
  };

  const resetText = () => {
    setVstxt(''); // Reset accumulated text
    if (vfinalRef.current) {
      vfinalRef.current.value = ''; // Clear textarea
    }
  };

  const { accounts } = useMsal();
  function Render() {
    try {
      setName(accounts[0].name);
    } catch (e) { }
  }

  useEffect(() => {
    setShowInput(path !== "/no-go");
  }, [path]);

  useEffect(() => {
    const chatMessages = messageList?.map((message) => ({
      ...message,
      messageDate: new Date(message.created_date),
    }));
    setChatMessagesWithContentdata(chatMessages);
  }, [messageList]);

  const [loading, setLoading] = useState(false);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
    }
  };
  const scrollToTops = () => {
    var element = document.getElementsByClassName("p-divider-content-unread");
    element[0]?.scrollIntoView({
      block: "start",
      inline: "nearest",
    });
  };

  useEffect(() => {
    if (isScrollToBottom) {
      scrollToBottom();
    } else {
      scrollToTops();
    }
  }, [chatMessagesWithContentdata, isChatModalOpen, isScrollToBottom]);

  useEffect(() => {
    if (isChatModalOpen) {
      setMessages([]);
      setIsScrollToBottom(true);
    }
  }, [isChatModalOpen]);

  useEffect(() => {
    Render();
  }, []);

  useEffect(() => {
    setMessages([...messageList]);
  }, [messageList]);

  useEffect(() => {
    if (isChatModalOpen) {

      dispatch(
        fetchMessages({
          mrn: `${mrn}`,
          usermail: userName,
        })
      );
    }
  }, [isChatModalOpen, mrn, userName]);

  const sendMsg = async () => {
    const redid = document.getElementById("inputtype").value;
    const trimmedMessage = redid.trim();
    setValue("message", "");
    resetText();
    if (trimmedMessage !== "") {
      const res = await dispatch(
        updateChatModalData({
          ...chatModalData,
          message: trimmedMessage,
          mrn: mrn,
          usermail: userName,
          employee_name: name,
          message_type_id: 1,

        })
      );
      setMessages([...messages, trimmedMessage]);
      setisNewMessageSent(true);
      setIsScrollToBottom(true);
      stopListening()
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(fetchAll(userEmail));
      }
    }
  };

  useEffect(() => {
    let refreshInterval;

    if (isChatModalOpen) {
      refreshInterval = setInterval(() => {
        handleRefresh();
      }, 60 * 1000);
    }

    return () => {
      clearInterval(refreshInterval);
    };
  }, [isChatModalOpen]);

  const handleRefresh = async () => {
    if (!loading && isChatModalOpen) {
      // setLoading(true);

      try {
        dispatch(fetchMessages.pending());
        const response = await dispatch(
          fetchMessages({
            mrn: `${mrn}`,
            usermail: userName,
          })

        );

        setMessages([]);
        setisNewMessageSent(false);
        scrollToBottom();
        setIsScrollToBottom(true);
      } catch (error) {
        console.error("Error refreshing messages", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleImageClick = (mrn) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  const handleFileChange = async (event, mrn) => {
    const file = event.target.files[0];
    if (file === null) return;
    const formData = new FormData();
    formData.append("patientMessage.message", "");
    formData.append("patientMessage.mrn", mrn);
    formData.append("patientMessage.usermail", userName);
    formData.append("patientMessage.message_type_id", 2);
    formData.append("file", file);
    try {
      const data = {
        ...chatModalData,
        mrn: mrn,
        usermail: userName,
        employee_name: name,
        message_type_id: 2,
      };
      const response = await dispatch(uploadFileForChat({ formData, data }));

    } catch (error) {
      console.error("Error uploading:", error);
    }
  };

  const footerContent = (
    <div className="send-msg">
      {showInput && (
        <>
          <InputTextarea
            type="text"
            placeholder="Type a message..."
            className="form-control custom-chat"
            id="inputtype"
            ref={vfinalRef}
            value={vstxt}
            onChange={(e) => setVstxt(e.target.value)} // Update the state on input change
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) { // Check for Enter key without Shift
                e.preventDefault(); // Prevents newline in InputTextarea
                sendMsg(); // Call your send function
              }
            }}
          />

          <div
            onClick={sendMsg}
            className="send-icon position-absolute top-50 end-0 d-flex align-items-center"
          >
            <div className="upload-icon">
              <i
                style={{ cursor: "pointer" }}
                className="me-2 pi pi-paperclip"
                onClick={() => {
                  handleImageClick(mrn);
                }}
              ></i>

              <input
                type="file"
                onChange={(e) => {
                  handleFileChange(e, mrn);
                }}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
            <i
              style={{ cursor: "pointer", color: isListening ? "red" : "black" }}
              className="pi pi-microphone"
              onClick={() => {
                if (isListening) {
                  stopListening();
                } else {
                  startListening();
                }
              }}
            ></i>
            <button className="common-btn m-0 rounded-0">
              <i className="pi pi-send" ></i>
            </button>

          </div>
        </>
      )}
    </div>
  );
  const toggleChat = (rowData) => {
    dispatch(toggleChatModal({ isChatModalOpen: false, id: mrn }));
  };

  function formatMessageDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return "Today";
    } else if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return "Yesterday";
    } else {
      // Format the date as needed, e.g., "MM/DD/YYYY"
      const options = { month: "2-digit", day: "2-digit", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    }
  }

  function formatDateString(dateString) {
    const date = new Date(dateString);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      // timeZone: "America/New_York",
    };

    return `${formatMessageDate(date)} ${date.toLocaleTimeString(
      "en-US",
      options
    )}`;
  }

  function formatDateStringWithoutTime(dateString) {
    const date = new Date(dateString);
    return `${formatMessageDate(date)}`;
  }

  const FileDownload = async (filename) => {
    try {
      const response = await dispatch(chatfileDownload(filename));

      const blob = new Blob([response.payload.data], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };



  const toggle = (rowData) => {
    dispatch(addNoteToggle({ isOpen: true, rowData: rowData }))
  }

  const assigntoggle = (rowData) => {
    dispatch(addAssignPtToggle({ isAssign: true, assignItem: rowData }))
  }
  const extractFilename = (messageText) => {
    const regex = /\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}(.*)/;
    const match = messageText.match(regex);
    return match ? match[1] : "Download";
  };

  return (
    <>
      <Dialog
        header={
          <>
            <Avatar
              className="avatar"
              alt="Remy Sharp"
              sx={{ width: 28, height: 28 }}
            />
            <h3 className="ms-2 mb-0 mt-1">{currentPatient}</h3>
            <Tooltip target=".custom-target-icon" className="custom-tooltip" />
            <button
              style={{ top: "10px" }}
              data-pr-tooltip="Refresh"
              data-pr-position="bottom"
              className="custom-target-icon common-btn me-5 refresh-btn"
              onClick={handleRefresh}
            >
              <i className="pi pi-replay" />
            </button>
          </>
        }
        visible={isChatModalOpen}
        position={position}
        style={{ width: "750px" }}
        onHide={stopListeningOnHide}
        footer={footerContent}
        draggable={false}
        resizable={false}
        className={`chat-title`}
        blockScroll="false"
        modal={true}
        closeIcon={false}
      >
        {modalLoading ? (
          <Spinner className="d-flex justify-content-center m-auto" />
        ) : (
          <div className="chat-box" ref={containerRef}>
            {chatMessagesWithContentdata?.map((message, index) => {
              if (message.isDateChanged === true) {
                return (
                  <Divider
                    key={`divider_${message.date}`}
                    className="custom-divider"
                    align="center"
                  >
                    <span style={{ fontSize: "14px" }}>
                      {formatDateStringWithoutTime(message?.date)}
                    </span>
                  </Divider>
                );
              } else {
                return (
                  <div key={message.message_id}>
                    <div>
                      <DeviderComponent
                        message={message}
                        chatMessagesWithContentdata={
                          chatMessagesWithContentdata
                        }
                        userName={userName}
                        isReadMessage={isReadMessage}
                        setIsScrollToBottom={setIsScrollToBottom}
                        lastReadDividerRef={lastReadDividerRef}
                        messagesEndRef={messagesEndRef}
                        isNewMessageSent={isNewMessageSent}
                      />
                    </div>
                    {message?.employee_email?.toLowerCase() ===
                      userName?.toLowerCase() ? (
                      <div
                        id="messages"
                        className={` left-chat chat-details d-flex flex-column justify-content-end custom-end`}
                        ref={
                          !message.is_read
                            ? message.message_id ==
                              message.user_last_read_message_id &&
                              message?.employee_email?.toLowerCase() !=
                              userName?.toLowerCase()
                              ? messagesUnReadRef
                              : null
                            : chatMessagesWithContentdata?.length - 1 === index
                              ? messagesEndRef
                              : null
                        }
                      >
                        <div className="d-flex justify-content-end align-items-end">
                          <div className="inner-chat right-messages">
                            <span>{message?.employee_name}</span>
                            <div className="note d-flex align-items-center justify-content-end">

                              {message?.message_type_id === 2 && (
                                <>
                                  {/* {!message?.pgnotes_flag && (

                                    <div onClick={() => { assigntoggle(message) }}>
                                      <img
                                        src={note}
                                        alt="note"
                                        className="me-2 tool"
                                        width="26"
                                        height="26"
                                        title="Assign to Pt Chart"
                                      />
                                    </div>
                                  )} */}
                                </>
                              )}

                              {message?.message_type_id === 1 && (
                                <>
                                  {!message?.pgnotes_flag && (
                                    <div
                                      onClick={() => { toggle(message) }}
                                      style={{ cursor: 'pointer' }}  // Add this inline style
                                    >
                                      <img
                                        src={note}
                                        alt="note"
                                        className="me-2 tool"
                                        width="26"
                                        height="26"
                                        title="Add to Progress Notes"
                                      />
                                    </div>
                                  )}
                                </>
                              )}


                              <p>
                                {message?.message_type_id === 2 ? (<>
                                  <Tool target=".download-icon" />
                                  <Download
                                    className="custom-target-icon custom-icon-width download-icon"
                                    onClick={() => {
                                      FileDownload(message.message_text);
                                    }}
                                    data-pr-tooltip={extractFilename(message.message_text)}
                                    data-pr-position="top"
                                  />

                                </>
                                ) : (
                                  <div className="mright">
                                    <p>{message?.message_text}</p>
                                  </div>

                                )}
                              </p>

                            </div>
                            <span
                              ref={
                                chatMessagesWithContentdata?.length - 1 ===
                                  index
                                  ? messagesEndRef
                                  : null
                              }
                            >
                              {formatDateString(message?.created_date)}
                              {/* {message?.created_date} */}
                            </span>
                          </div>
                          <div className="username">
                            <span>
                              {message?.employee_name
                                ?.split(" ")
                                .map((word) => word.charAt(0))
                                .join("")}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        id="messages"
                        className={`right-chat chat-details d-flex flex-column justify-content-start custom-start`}
                        ref={
                          !message.is_read
                            ? message.message_id ==
                              message.user_last_read_message_id &&
                              message?.employee_email?.toLowerCase() !=
                              userName?.toLowerCase()
                              ? messagesUnReadRef
                              : null
                            : chatMessagesWithContentdata?.length - 1 === index
                              ? messagesEndRef
                              : null
                        }
                      >
                        <div className={`d-flex justify-content-start  ${message?.employee_email === null ? "align-items-center mb-2" : "align-items-end"}`}>
                          {message?.employee_email === null ? (
                            <div className=" left-user">
                              <img
                                src={documenticon}
                                alt="note"
                                className="me-2 tool document-icon"
                                width="15"
                                height="15"

                              />
                            </div>
                          ) : (

                            <div className="username left-user">
                              <span>
                                {message?.employee_name
                                  ?.split(" ")
                                  .map((word) => word.charAt(0))
                                  .join("")}
                              </span>
                            </div>
                          )}
                          {/* .chat-box p { */}
                          <div className="inner-chat left-messages ">
                            {message?.employee_email === null ? (<>
                              <p className="document-msg">
                                <Link className="system-msg">
                                  {message?.message_text}
                                </Link>
                                <span className="message-timestamp">
                                  {formatDateString(message?.created_date)}
                                </span>
                              </p>

                            </>) : (
                              <>
                                <span>{message?.employee_name}</span>
                                <div className="message-container">
                                  <p className="main-p-download">
                                    {message?.message_type_id === 2 ? (
                                      <>
                                        <div style={{ position: 'relative' }}>
                                          <Tool target=".download-icon" position="top" />
                                          <Download
                                            className={`custom-target-icon download-icon download-icon-${message.message_id} item`}
                                            onClick={() => {
                                              FileDownload(message.message_text);
                                            }}
                                            data-pr-tooltip={extractFilename(message.message_text)}
                                            data-pr-position="top"
                                            style={{
                                              fontSize: window.innerWidth < 480 ? '16px' : '24px',
                                              padding: window.innerWidth < 480 ? '4px' : '8px',
                                            }}
                                          />

                                          {/* Add to Patient Chart link */}
                                          <span
                                            onClick={showDialog}
                                            style={{
                                              display: "none",
                                              overflow: "visible",
                                              color: '#00BFFF',
                                              cursor: 'pointer',
                                              textDecoration: 'underline',
                                              whiteSpace: 'nowrap', // Prevent the link from wrapping onto a new line
                                              position: 'absolute',  // Use absolute positioning relative to parent container
                                              left: '109%',
                                              top: '39px',
                                              marginLeft: '10px', // Add some space between the icon and the link
                                            }}
                                          >
                                            Add to Patient Chart
                                          </span>
                                        </div>
                                      </>
                                    )
                                      : (
                                        <>
                                          {message?.message_text}
                                        </>
                                      )}
                                  </p>

                                </div>


                                <span
                                  ref={
                                    chatMessagesWithContentdata?.length - 1 ===
                                      index
                                      ? messagesEndRef
                                      : null
                                  }
                                >
                                  {formatDateString(message?.created_date)}
                                  {/* {message?.created_date} */}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>

        )}
        <Modal
          isOpen={displayDialog}
          centered
          className="assign-modal"
          size="lg"

        >
          <ModalHeader toggle={closeDialog} className="w-100 align-items-start pb-2">
            Assign to Patient
          </ModalHeader>
          <ModalBody style={{ padding: '10px' }}>
            {/* Category Dropdown Section */}
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
              <label
                htmlFor="category"
                style={{
                  fontSize: '16px',
                  width: '20%',
                  marginRight: '10px',
                }}
              >
                Category
              </label>
              <Dropdown
                id="category"
                value={selectedCategory}
                options={category.map((item) => item.document_category)}
                onChange={(e) => setSelectedCategory(e.value)}
                placeholder="Select a Category"
                style={{
                  fontSize: '12px',
                  width: '78%',
                }}
              />
            </div>

            {/* Description Dropdown Section */}
            <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
              <label
                htmlFor="description"
                style={{
                  fontSize: '16px',
                  width: '20%',
                  marginRight: '10px',
                }}
              >
                Description
              </label>
              <Dropdown
                id="description"
                value={selectedDescription}
                options={description.map((item) => item.document_description)}
                onChange={(e) => setSelectedDescription(e.value)}
                placeholder="Select a Description"
                style={{
                  fontSize: '12px',
                  width: '78%',
                }}
              />
            </div>

            {/* Button Section */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '99%', marginTop: "10px" }}>
              <Button
                className="button-hover me-2"
                onClick={handleAttach}
                style={{
                  height: "40px",
                  width: '100px',
                  color: 'black',
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center', // Center text horizontally
                  alignItems: 'center', // Center text vertically
                  textAlign: 'center', // Ensure text alignment is set
                  borderColor: '#1B3D6D',
                  borderWidth: '1px', // Add border width
                }}

              >
                Attach
              </Button>

              <Button
                className="button-hover"
                onClick={closeDialog}
                style={{
                  width: '100px',
                  color: 'black',
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center', // Center text horizontally
                  alignItems: 'center', // Center text vertically
                  textAlign: 'center', // Ensure text alignment is set
                  borderColor: '#1B3D6D',
                  borderWidth: '1px', // Add border width
                  height: "40px",
                }}

              >
                Close
              </Button>

            </div>
          </ModalBody>
        </Modal>
      </Dialog>
      <AddNote userEmail={userEmail} lastName={lastName} firstName={firstName} mrn={mrn} />
      <AssignPtChart userEmail={userEmail} lastName={lastName} firstName={firstName} mrn={mrn} />

    </>
  );
};


// inner-chat left-messages

export const DeviderComponent = ({
  message,
  userName,
  setIsScrollToBottom,
  isNewMessageSent,
}) => {
  if (
    !message.is_read &&
    !isNewMessageSent &&
    message.message_id == message.user_last_read_message_id &&
    message?.employee_email?.toLowerCase() != userName?.toLowerCase()
  ) {
    setIsScrollToBottom(false);
  }
  return (
    <>
      {!message.is_read &&
        message.message_id == message.user_last_read_message_id &&
        message?.employee_email?.toLowerCase() != userName?.toLowerCase() && (
          <div>
            <Divider align="center" className="p-divider-content-unread">
              <span style={{ fontSize: "14px" }}>Last Read</span>
            </Divider>
          </div>
        )}
    </>
  );
};

export default ChatModal;