import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utility/api";
import { fetchAll } from "./patientSlice";



export const getProgressNoteSubject = createAsyncThunk("getProgressNoteSubject ", async () => {
    try {
        const response = await api.get(`/api/Patient/GetProgressNoteSubject`);
        return response.data;
    } catch (error) {
        console.error("Error fetching patient data:", error);
        throw error;
    }
});
export const getProgressNoteSubject1 = createAsyncThunk("getProgressNoteSubject1 ", async () => {
    try {
        const response = await api.get(`/api/Patient/GetProgressNoteSubject`);
        return response.data;
    } catch (error) {
        console.error("Error fetching patient data:", error);
        throw error;
    }
});

export const addPatientProgressNote = createAsyncThunk("addPatientProgressNote ", async ({ data, callback }, thunkApi) => {
    try {
        const response = await api.post(`/api/Patient/AddPatientProgressNote`, data);



        if (callback && response.data.success) {
            callback(response)
        }
        // await thunkApi.dispatch(fetchAll(userEmail));

        return response.data;
    } catch (error) {
        console.error("Error fetching patient data:", error);
        throw error;
    }
});

const noteSlice = createSlice({
    name: "notes",
    initialState: {
        loading: false,
        subjectItem: [],
        isNote: false,
        noteItem: [],
        isAddNote: false,           
        isAssign: false,
        assignItem: [],
        assignPtItem: []    
    },
    reducers: {
        addNoteToggle: (state, action) => {
            state.isNote = action.payload.isOpen;
            state.noteItem = action.payload.rowData
        },
        addAssignPtToggle: (state, action) => {
            state.isAssign = action.payload.isAssign;
            state.assignItem = action.payload.assignItem
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProgressNoteSubject.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProgressNoteSubject.fulfilled, (state, action) => {
            state.subjectItem = action.payload.data
        });
        builder.addCase(getProgressNoteSubject.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(getProgressNoteSubject1.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProgressNoteSubject1.fulfilled, (state, action) => {
            state.assignPtItem = action.payload.data
        });
        builder.addCase(getProgressNoteSubject1.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(addPatientProgressNote.pending, (state) => {
            state.isAddNote = true;
        });
        builder.addCase(addPatientProgressNote.fulfilled, (state) => {
            state.isAddNote = false
        });
        builder.addCase(addPatientProgressNote.rejected, (state) => {
            state.isAddNote = false;
        });

    },
});

export const { addNoteToggle, addAssignPtToggle } = noteSlice.actions;
export default noteSlice.reducer;