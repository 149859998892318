import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { addDocumentModal, existsPatientToggle, reviewToggle } from "../../redux/referralsSlice";
import warning from "../../assets/images/warning.svg"
import AddDocumentModal from "./AddDocumentModal";
import DateFormatter from "../../components/Filter/DateFormatter";
import ReviewStatusModal from "./ReviewStatusModal";


export default function CreatePatient({ userEmail }) {
    const dispatch = useDispatch();
    const { modalLoading, isExitPatientOpen, patientMNR } = useSelector((state) => state.referral);


    const lastItem = patientMNR?.data?.length ? patientMNR.data[patientMNR.data.length - 1] : null;

    const toggledocument = () => {
        dispatch(addDocumentModal({ isOpen: true }))
    }



    const toggle = () => {
        dispatch(existsPatientToggle({ isOpen: false }));
    };


    return (
        <>
            <Modal className="referral-modal" isOpen={isExitPatientOpen} toggle={toggle} centered>
                <ModalHeader className="w-100 align-items-start pb-2 alert alert-danger" toggle={toggle}>
                    <div className="d-flex align-items-center"> <img src={warning} alt="warning" className="me-2" />Patient already exists</div>
                </ModalHeader>
                <ModalBody className="card border-0">
                    <div className="border rounded card-body patient-exists">
                        <Row>
                            <Col md="6">
                                <Row>
                                    <Col md="6">
                                        <p>MRN</p>
                                    </Col>
                                    <Col md="6">
                                        <p>{lastItem?.mrn}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <p>Prescriber</p>
                                    </Col>
                                    <Col md="6"><p>{lastItem?.prescriber_name}</p></Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <p>First Name</p>
                                    </Col>
                                    <Col md="6"><p>{lastItem?.first_name}</p></Col>
                                </Row>
                                <Row>
                                    <Col md="6"><p>Last Name</p></Col>
                                    <Col md="6"><p>{lastItem?.last_name}</p></Col>
                                </Row>
                                <Row>
                                    <Col md="6"><p>Reference Date</p> </Col>
                                    <Col md="6"><p> <DateFormatter date={lastItem?.referral_date} /></p></Col>
                                </Row>

                            </Col>
                            <Col md="6">


                                <Row>
                                    <Col md="6"><p>State</p> </Col>
                                    <Col md="6"><p>{lastItem?.state_name}</p></Col>
                                </Row>
                                <Row>
                                    <Col md="6"><p>City</p> </Col>
                                    <Col md="6"><p>{lastItem?.city_name}</p></Col>
                                </Row>

                                <Row>
                                    <Col md="6"><p>Street Address</p> </Col>
                                    <Col md="6"><p>{lastItem?.address_1}</p></Col>
                                </Row>

                                <Row>
                                    <Col md="6"><p>Date of Birth</p> </Col>
                                    <Col md="6"><p> <DateFormatter date={lastItem?.date_of_birth} /></p></Col>
                                </Row>
                                <Row>
                                    <Col md="6"><p>Zip</p> </Col>
                                    <Col md="6"><p>{lastItem?.zipcode}</p></Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-3">
                        <p className="fw-bold">Add document to the existing patient profile?</p>
                    </div>

                    <div className="d-flex mt-2 pt-3 mt-3 justify-content-end">

                        {/* <button type="submit" disabled={modalLoading} className="common-btn" onClick={handleReview}>
                            Review
                            {modalLoading && <Spinner className="ms-2" size="sm" color="light" />}
                        </button> */}
                        <button type="submit" disabled={modalLoading} className="common-btn mx-2" onClick={toggledocument}>
                            ADD
                            {modalLoading && <Spinner className="ms-2" size="sm" color="light" />}
                        </button>
                        <button type="button" className="custom-button me-2" onClick={toggle}>
                            Cancel
                        </button>

                    </div>

                </ModalBody>
            </Modal>
            <ReviewStatusModal userEmail={userEmail} />
            <AddDocumentModal patientMNR={patientMNR} userEmail={userEmail} />
        </>

    );
}
