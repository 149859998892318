import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { toggleViewModal } from '../../redux/referralsSlice';
import DateFormatter from '../../components/Filter/DateFormatter';
import Document from './Document';

function ViewDocumentModal() {

    const dispatch = useDispatch();

    const { documentItem, isDocumentView } = useSelector(state => state.referral);
    const toggle = (rowData) => {
        dispatch(toggleViewModal({ isOpen: false, rowData: rowData }));
    };
    return (
        <div>
            <Modal isOpen={isDocumentView} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>Documents</ModalHeader>
                <ModalBody className='card border-0'>
                    <div className='border rounded card-body mb-3 patient-exists'>
                        <Row>
                            <Col md="6">
                                <Row>
                                    <Col md="6">
                                        <h6><b>MRN:</b></h6>
                                    </Col>
                                    <Col md="6">
                                        <h6>{documentItem?.mrn}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6"><h6><b>Date of Birth</b> </h6> </Col>
                                    <Col md="6"><h6> <DateFormatter date={documentItem?.date_of_birth} /></h6></Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <Row>
                                    <Col md="6">
                                        <h6><b>First Name:</b></h6>
                                    </Col>
                                    <Col md="6">
                                        <h6>{documentItem?.first_name}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6"><h6><b>Last Name</b> </h6> </Col>
                                    <Col md="6"><h6>{documentItem?.last_name}</h6></Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <Document documentItem={documentItem} />
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ViewDocumentModal;
