
export const filterOptions = [
    { globalFilterName: "selectedstatus", isBlankField: false, apiFieldName: "patient_status", placeholder: "Status" },
    { globalFilterName: "selectedSites", isBlankField: true, apiFieldName: "site", placeholder: "All Sites" },
    { globalFilterName: "selectedAssignees", isBlankField: true, apiFieldName: "assigned_to", placeholder: "Sales Representative" },
    { globalFilterName: "selectedIntakes", isBlankField: true, apiFieldName: "intake", placeholder: "Intake" },
    { globalFilterName: "selectedCaseManagers", isBlankField: true, apiFieldName: "case_manager", placeholder: "Case Manager" },
    { globalFilterName: "selectedCategories", isBlankField: true, apiFieldName: "pat_category", placeholder: "Categories" },
    { globalFilterName: "selectedPrescribers", isBlankField: true, apiFieldName: "prescriber_name", placeholder: "Prescribers" },
    { globalFilterName: "selectedTeams", isBlankField: false, apiFieldName: "teams", placeholder: "Teams" },
    { globalFilterName: "nps_mcr_uc_string", isBlankField: true, apiFieldName: "nps_mcr_uc", placeholder: "Task" },
    { globalFilterName: "seclectNextion", isBlankField: true, apiFieldName: "next_action_by", placeholder: "Next Action By" },
    { globalFilterName: "slectFlag", isBlankField: false, apiFieldName: "is_flag", placeholder: "flag" },


];
export const nogoOptions = [
    { globalFilterName: "selectedstatus", isBlankField: false, apiFieldName: "patient_status", placeholder: "Status" },
    { globalFilterName: "selectedSites", isBlankField: true, apiFieldName: "site", placeholder: "All Sites" },
    { globalFilterName: "selectedAssignees", isBlankField: true, apiFieldName: "assigned_to", placeholder: "Sales Representative" },
    { globalFilterName: "selectedIntakes", isBlankField: true, apiFieldName: "intake", placeholder: "Intake" },
    { globalFilterName: "selectedCaseManagers", isBlankField: true, apiFieldName: "case_manager", placeholder: "Case Manager" },
    { globalFilterName: "selectedCategories", isBlankField: true, apiFieldName: "pat_category", placeholder: "Categories" },
    { globalFilterName: "selectedPrescribers", isBlankField: true, apiFieldName: "prescriber_name", placeholder: "Prescribers" },
    { globalFilterName: "selectedTeams", isBlankField: false, apiFieldName: "teams", placeholder: "Teams" },
    { globalFilterName: "nps_mcr_uc_string", isBlankField: false, apiFieldName: "nps_mcr_uc", placeholder: "Task" },
    { globalFilterName: "selectNoGo", isBlankField: true, apiFieldName: "nogo_reason", placeholder: "No Go Reason" },
    { globalFilterName: "slectFlag", isBlankField: false, apiFieldName: "is_flag", placeholder: "flag" },
];
export const expiringFilterOptions = [
    { globalFilterName: "selectedstatus", isBlankField: false, apiFieldName: "patient_status", placeholder: "Status" },
    { globalFilterName: "selectedSites", isBlankField: true, apiFieldName: "site_name", placeholder: "All Sites" },
    { globalFilterName: "selectedAssignees", isBlankField: true, apiFieldName: "sales_rep", placeholder: "Sales Representative" },
    { globalFilterName: "selectedPayor", isBlankField: true, apiFieldName: "insuranse_comp", placeholder: "Payor" },
    { globalFilterName: "selectedCaseManagers", isBlankField: true, apiFieldName: "case_manager", placeholder: "Case Manager" },
    { globalFilterName: "selectedCategories", isBlankField: true, apiFieldName: "category", placeholder: "Categories" },
    { globalFilterName: "selectedPrescribers", isBlankField: true, apiFieldName: "prescriber_name", placeholder: "Prescribers" },
    { globalFilterName: "selectedTeams", isBlankField: false, apiFieldName: "teams", placeholder: "Teams" },
    { globalFilterName: "selectedAssign", isBlankField: false, apiFieldName: "assign_emp", placeholder: "Assignment" },
    { globalFilterName: "seltectIntake", isBlankField: false, apiFieldName: "intake_report", placeholder: "Intake Coordinator" },
];

export const physicianFilterOptions = [
    { globalFilterName: "selectedCategories", isBlankField: true, apiFieldName: "category", placeholder: "Categories" },
];

export const referralFilterOptions = [
    { globalFilterName: "selectedSites", isBlankField: true, apiFieldName: "site", placeholder: "All Sites" },
    { globalFilterName: "selectedCategories", isBlankField: true, apiFieldName: "category", placeholder: "Categories" },
];



