
import React, { useEffect, useRef, useState } from 'react';
import MultiSelect from '../../components/Filter/FilterDropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AiOutlineDelete } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { IoMdStarOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { AddNewCustomFilter } from '../../redux/advancedFilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GetCustomFilterList } from '../../redux/customFilterSlice';
import { useUserEmail } from '../Context';
import '../../assets/css/advancefilter.css'
import { isEqual } from 'lodash';
const AdvancedFilter = ({
    filterOptions,
    data,
    globalFilterOptions,
    setGlobalFilterOptions,
    handleClear,
    showAdvancedFilter,
    handleAdvancedFilter,
    userRoles,
    setIsAssign,
    isAssign,




}) => {
    const userEmail = useUserEmail();


    const op = useRef(null); // Reference for OverlayPanel
    const [filterValue, setFilterValue] = useState("");
    const [error, setError] = useState("");

    const [favoriteFilters, setFavoriteFilters] = useState([]); // State for favorite filters
    const [newFilterName, setNewFilterName] = useState(''); // For inputting new filter names
    const [savedFilters, setSavedFilters] = useState([]); // State for saved filters

    const [isFilterError, setIsFilterError] = useState(false);
    const [showDataTable, setShowDataTable] = useState(true);
    const [showSaveOption, setShowSaveOption] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();



    const currentPath = location.pathname;
    // console.log("cureent", currentPath)
    // const isNewStart = currentPath === "/new-start";

    // useEffect(() => {
    //     console.log("cureent", currentPath)
    //     const isNewStart = currentPath === "/new-start";

    // }, [])


    const {
        filters } = useSelector((state) => state.customFilter)

    useEffect(() => {
        const savedFilters = filters || [];
        setFavoriteFilters(savedFilters);

    }, [filters]);
    useEffect(() => {
        if (userEmail) {
            // Fetch filters when the user logs in or when the component is mounted
            dispatch(
                GetCustomFilterList({
                    email_address: userEmail,
                    preference_name: 'New_Starts_page_filter',
                    usage: 'USER',
                })
            ).then(() => {
                // After fetching filters, the component will automatically re-render with the filters
                console.log('Filters fetched successfully');
            }).catch((error) => {
                console.error("Error fetching filter list:", error);
            });
        }
    }, [userEmail, dispatch]);  // Re-run when userEmail or dispatch changes


    const handleStarClick = (e) => {
        setShowSaveOption(true); // Show input for filter name
        op.current?.show(e); // Pass the event to show method
    };
    const advancedFilterClass = showAdvancedFilter ? "show" : "hide";

    const hasRequiredRole = (roles, requiredRoles) => {
        return roles?.some(role => requiredRoles?.includes(role.role));
    };

    const requiredRoles = ["Super User", "Intake Manager", "Operations"];
    const canAssign = hasRequiredRole(userRoles, requiredRoles);

    useEffect(() => {
        if (userEmail) {
            // Dispatch the action to fetch filters
            dispatch(GetCustomFilterList({
                email_address: userEmail,
                preference_name: 'New_Starts_page_filter',
                usage: 'USER',
            }));
        }
    }, [userEmail, dispatch]);  // Run the effect when userEmail changes

    const handleSaveFilter = () => {
        refreshCustomFilterList();

        if (!newFilterName.trim()) {
            setError("Please enter a filter name.");
            setShowSaveOption(false);
        } else if (filters.some((filter) => filter.name === newFilterName)) {
            setError("Filter already exists.");
            setIsFilterError(true);
            setShowDataTable(false);
        } else {
            const normalizeObject = (obj) => {
                return JSON.stringify(
                    Object.keys(obj)
                        .sort()
                        .reduce((acc, key) => {
                            acc[key] = Array.isArray(obj[key]) ? obj[key].sort() : obj[key];
                            return acc;
                        }, {})
                );
            };

            const isFilterExist = filters.some((filter) => {
                return normalizeObject(JSON.parse(filter.json_value)) === normalizeObject(globalFilterOptions);
            });

            if (isFilterExist) {
                setError("A filter with the same criteria already exists.");
                setIsFilterError(true);
                setShowDataTable(false);
            } else {
                // Dispatch AddNewCustomFilter action
                dispatch(
                    AddNewCustomFilter({
                        preference_name: 'New_Starts_page_filter',
                        filter_name: newFilterName,
                        preference_json_value: JSON.stringify(globalFilterOptions),
                        usage: "USER",
                        type: 1,
                        custom_preference_id: 0,
                        email_address: userEmail,
                    })
                ).then(() => {
                    // After saving, dispatch the action to refresh the custom filter list
                    dispatch(
                        GetCustomFilterList({
                            email_address: userEmail,
                            preference_name: 'New_Starts_page_filter',
                            usage: "USER",
                        })
                    ).then(() => {
                        setNewFilterName("");  // Clear input
                        setShowSaveOption(false);  // Hide save option
                        setError("");  // Clear error
                    });
                });
            }
        }
    };





    const applyFilter = (filter) => {
        try {
            const parsedFilter = JSON.parse(filter.json_value); // Parse if it's a JSON string
            setGlobalFilterOptions(parsedFilter); // Apply the saved filter's options
            op.current.hide(); // Close the overlay panel after applying the filter
        } catch (e) {

        }
    };


    const applySavedFilter = (filter) => {
        // Apply the saved filter's options
        setGlobalFilterOptions(filter.options);
        op.current.hide(); // Close the overlay panel after applying the filter
    };
    const FilterActionBody = (rowData) => {
        return (
            <Button
                label="Apply"
                onClick={() => applyFilter(rowData)}
                className="p-button-text"
            />
        );
    };

    const refreshCustomFilterList = () => {
        dispatch(
            GetCustomFilterList({
                email_address: userEmail,
                preference_name: 'New_Starts_page_filter',
                usage: "USER",

            })
        );
    };

    const handleDeleteFilter = (rowData) => {
        // Ensure that preference_json_value is an object, not a string
        const preferenceJsonValue = JSON.stringify(rowData.json_value);

        const requestData = {
            preference_name: "New_Starts_page_filter",
            filter_name: rowData.name,
            preference_json_value: preferenceJsonValue,  // Make sure it's properly stringified
            usage: "USER",
            type: 3,
            custom_preference_id: rowData.custom_preference_id,
            email_address: userEmail,
        };

        dispatch(AddNewCustomFilter(requestData))
            .then(response => {
                refreshCustomFilterList()
            })
            .catch(error => {
                console.error('Error occurred:', error.response || error);
            });
    };


    return (
        <div className="advance-filter d-flex align-items-center justify-content-between">
            <div className='d-flex align-items-center gap-1'>
                <button
                    className={`custom-button m-0 mb-2 ${showAdvancedFilter ? "common-btn" : ""} advanced-filter-button`}
                    style={{ width: "9rem" }}
                    onClick={handleAdvancedFilter}
                >
                    Advanced Filters
                </button>





                {canAssign ? (
                    <button
                        className={`${isAssign ? "common-btn" : ""} custom-button ms-3 mb-2 advanced-filter-button`}
                        onClick={() => setIsAssign(!isAssign)}
                    >
                        Assign to
                    </button>
                ) : null}




                <div className='ms-3 reset-btn' style={{ cursor: "pointer" }} onClick={handleClear}>
                    Reset
                </div>
            </div>

            <div className=''>
                {currentPath === "/" && (
                    <div
                        className="star-icon pb-6"
                        style={{
                            cursor: "pointer",
                            height: "30px",
                            fontSize: "30px",
                        }}
                        onClick={handleStarClick}
                    >
                        <IoMdStarOutline />
                    </div>
                )}
                <OverlayPanel ref={op} style={{ width: '300px', borderRadius: "9px", padding: '1rem' }}>
                    <div className="favourite-filters">
                        {/* Save Filter Section */}
                        <div className="filter-name mb-4">
                            <div className="flex justify-between items-center mb-2">
                                <span className="text-xl font-bold dark:text-gray-200">Save Filter</span>
                                <IoCloseSharp
                                    onClick={() => op.current?.hide()}
                                    style={{
                                        cursor: 'pointer',
                                        width: '24px',
                                        height: '24px',
                                        color: '#333',
                                        marginLeft: "100px"
                                    }}
                                />
                            </div>

                            <p className="text-xs text-gray-500 mb-3">Mark filter as favourite</p>

                            <label className="text-sm font-semibold mb-1 block" htmlFor="filter-name">Filter Name</label>
                            <InputText
                                className="p-2 mb-2 w-full"
                                id="filter-name"
                                placeholder="Write filter name"
                                value={newFilterName}
                                onChange={(e) => setNewFilterName(e.target.value)}
                            />

                            {/* Error message */}
                            {error && <p className="text-red-500 text-xs mb-2">{error}</p>}

                            <Button label="Save Filter" className="w-full" outlined onClick={handleSaveFilter} />
                        </div>

                        {/* Saved Filters Table (Always visible) */}
                        <div className="saved-filter-list mt-4">
                            <div className="flex justify-between items-center mb-2">
                                <h4 className="text-sm font-bold">Saved Filters</h4>


                            </div>

                            {/* Scrollable Table */}
                            <div style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ddd', borderRadius: '8px', padding: '0.5rem' }}>
                                <DataTable
                                    value={filters}
                                    scrollable
                                    scrollDirection="vertical"
                                    style={{ width: '100%' }}
                                    scrollHeight="150px"
                                >
                                    <Column header="Filter Name" field="name" style={{ padding: '8px 10px' }} />
                                    <Column
                                        header="Actions"
                                        body={(rowData) => (
                                            <div className="flex gap-2">
                                                <Button
                                                    label="Apply"
                                                    onClick={() => applyFilter(rowData)}
                                                    className="p-button-text p-button-sm p-button-outlined"
                                                    style={{ padding: '4px 8px', fontSize: '0.8rem' }}
                                                />
                                                <AiOutlineDelete
                                                    onClick={() => handleDeleteFilter(rowData)}
                                                    style={{
                                                        color: "black",
                                                        cursor: "pointer",
                                                        width: "25px",
                                                        height: "20px",

                                                        marginTop: "6px",


                                                    }}
                                                />
                                            </div>
                                        )}
                                        style={{
                                            padding: '8px 10px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </OverlayPanel>




            </div>
            {showAdvancedFilter && (
                <div className="fadein animation-duration-500 box def-marg">
                    <div className={`show-filterlist position-absolute start-0 end-0 w-100 d-flex align-items-center flex-wrap ${advancedFilterClass}`}>
                        <div className='new-start-filter'>
                            {filterOptions.map(({ globalFilterName, apiFieldName, placeholder, isBlankField }) => {
                                let options = [];

                                if (data) {
                                    options = data.reduce((acc, item) => {
                                        if (apiFieldName === "is_flag") {
                                            const fieldValue = item[apiFieldName];
                                            const flagLabel = fieldValue ? "Flag" : "Unflag";
                                            if (!acc.some(option => option.label === flagLabel)) {
                                                acc.push({ label: flagLabel, value: fieldValue });
                                            }
                                        }

                                        if (apiFieldName !== "is_flag") {
                                            const fieldValue = item[apiFieldName];

                                            if (typeof fieldValue === 'boolean') {
                                                if (!acc.some(option => option.label === String(fieldValue))) {
                                                    acc.push({ label: String(fieldValue), value: fieldValue });
                                                }
                                            } else if (Array.isArray(fieldValue)) {
                                                fieldValue.forEach(value => {
                                                    if (!acc.some(option => option.label === value)) {
                                                        acc.push({ label: value, value: value });
                                                    }
                                                });
                                            } else if (fieldValue) {
                                                if (!acc.some(option => option.label === fieldValue)) {
                                                    acc.push({ label: fieldValue, value: fieldValue });
                                                }
                                            }
                                        }
                                        return acc;
                                    }, []).sort((a, b) => a.label.localeCompare(b.label));

                                    // Add blank option if necessary
                                    if (isBlankField) {
                                        options.unshift({ label: "(Blank)", value: "" }); // Ensure blank is represented as an empty string
                                    }
                                }

                                return (
                                    <MultiSelect
                                        key={globalFilterName}
                                        className="newstartfilter"
                                        value={globalFilterOptions[globalFilterName]}
                                        options={options}
                                        onChange={(e) => {
                                            setGlobalFilterOptions((prev) => ({
                                                ...prev,
                                                [globalFilterName]: e.value,
                                            }));
                                        }}
                                        placeholder={placeholder}
                                        maxSelectedLabels={false}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdvancedFilter;