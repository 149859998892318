import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { fileDownload } from '../../redux/referralsSlice';
import download from '../../assets/images/download.svg'
import DateFormatter from '../../components/Filter/DateFormatter';

function Document({ documentItem }) {

    const dispatch = useDispatch();
    let documentShowing = [];
    if (documentItem?.document_path && documentItem?.document_path !== "Test.pdf") {
        documentShowing = JSON.parse(documentItem?.document_path) || [];
    }
    const staticData = documentShowing?.map(item => ({
        documentName: item?.file_name,
        path: item?.path,
        dateUploaded: item?.created_date
    }));


    const handleDownload = async (rowData) => {
        const filename = rowData.path;
        const originalName = rowData.documentName;

        try {
            const response = await dispatch(fileDownload(filename));
            const blob = new Blob([response.payload.data], { type: "image/png" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = originalName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const actionHandler = (rowData) => {
        return (
            <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                    <img src={download} alt='download' onClick={() => handleDownload(rowData)} style={{ cursor: 'pointer' }} />
                </div>
            </div>
        )
    }
    return (
        <div>
            <DataTable value={staticData} className="doucment-table">
                <Column field="documentName" header="Document Name" sortable></Column>
                <Column field="dateUploaded" body={(rowData) => <DateFormatter date={rowData?.dateUploaded} />} header="Date Uploaded" sortable></Column>
                <Column header="Action" body={actionHandler}></Column>
            </DataTable>
        </div>
    );
}

export default Document;
