import { Calendar } from 'primereact/calendar'
import React from 'react'
import { Col } from 'reactstrap'

const DateRangeFilter = ({ setEndDate, startDate, setStartDate, endDate }) => {
    return (
        <>
            <Col xxl="4" xl="4" lg="auto" md="8" className="mb-xxl-1 mb-xl-2 mb-lg-0 mb-2">
                <div className="row position-relative">
                    <div className="col-md-6 mb-md-0 mb-2">
                        <Calendar
                            placeholder="Start Date"
                            value={startDate}
                            onChange={(e) => {
                                setStartDate(e.value);

                            }}
                            className="form-control custom-date overflow-hidden pe-0"
                            showIcon
                            maxDate={endDate ? endDate : null}
                        />
                    </div>
                    <div className="col-md-6">
                        <Calendar
                            placeholder="End Date"
                            value={endDate}
                            onChange={(e) => {

                                setEndDate(e.value);

                            }}
                            className="form-control custom-date overflow-hidden pe-0"
                            showIcon
                            minDate={startDate ? startDate : null}
                        />

                    </div>
                </div>
            </Col>
        </>
    )
}

export default DateRangeFilter