import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { documentmodal, fetchDocument, pdfModal } from '../../redux/patientSlice';
import DateFormatter from '../../components/Filter/DateFormatter';
import magnifying from '../../assets/images/document-search-icon.svg';
import ModalFullscreenExample from './Pdfviewer';




function DocumentModal({ mrn, fullname }) {
  const dispatch = useDispatch();
  const { isDocumentOpen, documentOpenID, documentList } = useSelector(state => state.patient);

  const [filename, setFileName] = useState(null);
  const [foldername, setFolderName] = useState(null);
  const [secondfolder, setSecondfolder] = useState(null);

  useEffect(() => {

    if (isDocumentOpen && documentOpenID) {
      dispatch(fetchDocument(documentOpenID));
    }
  }, [dispatch, isDocumentOpen, documentOpenID]);

  const toggle = () => {
    dispatch(documentmodal({ isOpen: false, id: null, rowData: null }));
  };

  const togglePdf = () => {
    dispatch(pdfModal({ isOpen: true }));
  };

  const viewDocument = (rowData) => {
    return (
      <div style={{ cursor: "pointer" }} onClick={() => { togglePdf(); setFolderName(rowData.folder1); setFileName(rowData.filename); setSecondfolder(rowData.folder2) }}>
        <img src={magnifying} alt='serach' width={"24px"} height={"24px"} />
      </div>
    )
  }

  return (
    <div>
      <Modal isOpen={isDocumentOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{mrn} - {fullname}</ModalHeader>

        <DataTable value={documentList}>
          <Column field="doc_type" header="Doc Type" sortable></Column>
          <Column field="date_sent" header="Date" body={(rowData) => <DateFormatter date={rowData.date_sent} />} sortable></Column>
          <Column field="modified_By" header="Entered/Uploaded By" sortable></Column>
          <Column field="order_description" header="Order Description" sortable></Column>
          <Column field="oriG_FILE" header="Document" sortable body={viewDocument}></Column>
        </DataTable>
        <ModalFullscreenExample filename={filename} foldername={foldername} secondfolder={secondfolder} />
      </Modal>
    </div>
  );
}

export default DocumentModal;
