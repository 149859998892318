import React, { useEffect, useState } from "react";
import Sidebar from "../src/components/Sidebar/sidebar";
import { Container } from "reactstrap";
import Header from "../src/components/Header/header";
import { ScrollTop } from "primereact/scrolltop";
import "../src/assets/css/chat.css"

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./redux/store";
import PatientList from "./view/patient/patientsList";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import ExpiringAuths from "./view/ExpiringAuthorization/ExpiringAuths";
import ComponentSpinner from "./components/spinner/Loading-spinner";
import Nogos from "./view/Nogo/Nogos";
import Referral from "./view/Referrals/referral";
import Main from "./Router";
import { useAuth } from "./ConfigLogin";
import { NotFound } from "./view/NotFound.js/NotFound";
import { getAssignRoleByUser } from "./redux/permissionSlice";
import PrivateRoute from "./auth/PrivateRoute";
import { UserProvider } from "./components/Context";


function Layout({ userEmail, name }) {

  const { isAuthenticated, acquireToken } = useAuth();


  useEffect(() => {
    isAuthenticated && acquireToken();
  }, [isAuthenticated])

  return (
    <>

      <Header userEmail={userEmail} name={name} />
      <Container className="custom-container">
        <div className="sidebar">
          <Sidebar userEmail={userEmail} />
        </div>
        <div className="main-content">
        <UserProvider userEmail={userEmail}>
                        <Main />
                    </UserProvider>
        </div>
        <ScrollTop threshold={100} style={{ backgroundColor: "#1B3D6D" }} className="w-2rem h-2rem border-round-md" icon="pi pi-arrow-up text-base" />
      </Container>
    </>
  );
}

function App() {
  useMsalAuthentication(InteractionType.Redirect);
  const [m_strUser, setm_strUser] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const { accounts } = useMsal();
  const [showSpinner, setShowSpinner] = useState(true);

  const dispatch = useDispatch();

  const { assignRoleByUser } = useSelector((state) => state.permissions)

  function Render() {
    const { accounts } = useMsal();

    try {
      setm_strUser(accounts[0].username);
      setName(accounts[0].name);
    } catch (e) { }
  }

  useEffect(() => {
    if (accounts.length > 0) {
      setm_strUser(accounts[0].username);
      setName(accounts[0].name);
      setLoading(false);
      setTimeout(() => {
        setShowSpinner(false);
      }, 5000);
    }
    dispatch(getAssignRoleByUser(accounts[0]?.username))
  }, [accounts]);



  if (loading || showSpinner) {
    return <><ComponentSpinner /></>;
  }



  const newstart = assignRoleByUser?.data?.some(role => role.page === "New Starts" && role.read === "Y");
  const expauth = assignRoleByUser?.data?.some(role => role.page === "Expiring Authorizations" && role.read === "Y");
  const newrefral = assignRoleByUser?.data?.some(role => role.page === "New Referrals" && role.read === "Y");
  const nogo = assignRoleByUser?.data?.some(role => role.page === "No Go" && role.read === "Y");


  if (m_strUser !== "") {
    return (
      <>
        <Router>
          <Routes>

            <Route path="/" element={<Layout name={name} userEmail={m_strUser} />}>

              <Route path="/unauthorized" element={<NotFound />} />

              {newstart ? <Route path="/" element={<PrivateRoute element={<PatientList name={name} userEmail={m_strUser} />} userEmail={m_strUser} />} /> : null}


              {expauth ? (
                <Route path="/expiring_auth" element={<PrivateRoute element={<ExpiringAuths name={name} userEmail={m_strUser} />} userEmail={m_strUser} />} />
              ) : null}


              {nogo && (
                <Route path="/no-go" element={<PrivateRoute element={<Nogos name={name} userEmail={m_strUser} />} userEmail={m_strUser} />} />
              )}


              {newrefral && (
                <Route path="/new-referrals" element={<PrivateRoute element={<Referral name={name} userEmail={m_strUser} />} userEmail={m_strUser} />} />
              )}

              {!newstart && !expauth && !newrefral && !nogo && (
                <Route path="/unauthorized" element={<PrivateRoute element={<NotFound />} userEmail={m_strUser} />} />
              )}

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </>
    );
  } else {
    return (
      <>
        {Render()}
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              Authenticating User...
            </Route>
          </Routes>
        </Router>
      </>
    );
  }
}

export default App;
