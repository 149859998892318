import {
  BrowserCacheLocation,
  EventType,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    tenantId: process.env.REACT_APP_TENANTID, // Your tenant ID goes here
    clientId: process.env.REACT_APP_CLIENTID, // Your client (application) ID goes here
    authority: process.env.REACT_APP_AUTHORITY, // Replace the last part with your tenant ID
    redirectUri: process.env.REACT_APP_REDIRECTURI, // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Warning,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["api://e62dad74-1f21-4dd3-a762-15e00c45319b/access_as_user"],
};

// debugger;
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }

  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    if (msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  }
});

export const appRoles = {
  Admin: "",
};
