import React from 'react';
import Filterdata from "../../components/Filter/Filterdata";
import FirstName from "../../components/Filter/FirstName";
import MrnSearch from "../../components/Filter/MrnSearch";
import AdvancedFilterPrescriber from './AdvancedFilterPrescriber';


export const prescriberFilterOptions = [
    { globalFilterName: "prescriber", isBlankField: false, apiFieldName: "prescriber", placeholder: "Prescriber" },
];

const FilterSection = ({
    item,
    filters,
    handleFilterChange,
    globalFilterValue,
    onGlobalFilterChange,
    globalFilterOptions,
    setGlobalFilterOptions,
}) => {
    return (
        <Filterdata
            Mrnsearch={
                <MrnSearch
                    data={item}
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                />
            }
            FirstNamesearch={
                <FirstName
                    data={item}
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    globalFilterValue={globalFilterValue}
                    onGlobalFilterChange={onGlobalFilterChange}
                />
            }
            renderAdvancedFilters={
                <AdvancedFilterPrescriber
                    data={item}
                    filterOptions={prescriberFilterOptions}
                    globalFilterOptions={globalFilterOptions}
                    setGlobalFilterOptions={setGlobalFilterOptions}
                />
            }
        />
    );
};

export default FilterSection;