import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch, useSelector } from "react-redux";
import { togglePendingStatusModal, updatePendingStatus } from "../../redux/patientSlice";
import { toast, ToastContainer } from 'react-toastify';
import { Spinner } from "reactstrap";

const PendingStatusModal = ({ userEmail }) => {

    const { pendingStatusModal, statusDescription, roleNames } = useSelector((state) => state.patient);




    const dispatch = useDispatch();

    const [statusList, setStatusList] = useState([]);
    const [roleNamesList, setRoleNamesList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedRole, setSelectedRole] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const newData = statusDescription.filter((item) => {
            return item.status_description === "Pending - Intake" ||
                item.status_description === "Pending - Nursing" ||
                item.status_description === "Pending - Pharmacy" ||
                item.status_description === "Pending - Patient"
        });
        setStatusList(newData);
    }, [statusDescription]);

    useEffect(() => {
        const newData = roleNames.filter((item) => {
            return item.role_name === "Intake" ||
                item.role_name === "Pharmacy" ||
                item.role_name === "Nurse"
        });
        setRoleNamesList(newData);
    }, [roleNames]);

    useEffect(() => {
        if (selectedStatus !== 29) {
            setSelectedRole("");
        }
    }, [selectedStatus]);

    useEffect(() => {
        if (pendingStatusModal?.data && pendingStatusModal?.data?.pending_sub_status) {
            const getStatus = statusList.filter((item) => {
                return item.status_description === pendingStatusModal?.data?.pending_sub_status
            });
            setSelectedStatus(getStatus[0]?.status_id);
        }
        if (pendingStatusModal?.data && pendingStatusModal?.data?.follow_up_by_role) {
            const getRole = roleNamesList.filter((item) => {
                return item.role_name === pendingStatusModal?.data?.follow_up_by_role
            });
            setSelectedRole(getRole[0]?.role_id);
        }
    }, [pendingStatusModal?.data]);

    useEffect(() => {
        if (!pendingStatusModal?.isOpen) {
            setSelectedRole("");
            setSelectedStatus("");
        }
    }, [pendingStatusModal?.isOpen]);

    const displayToast = ({ msg, type }) => toast[type](msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const handleUpdate = async () => {
        setLoading(true);
        const newData = {
            patient_sub_status_id: pendingStatusModal.data?.patient_sub_status_id ? pendingStatusModal.data?.patient_sub_status_id : 0,
            mrn: pendingStatusModal.data?.mrn?.toString(),
            status_id: selectedStatus,
            follow_up_by_role: selectedRole ? selectedRole : null,
            created_by: userEmail
        };
        const res = await dispatch(updatePendingStatus({ data: newData }));
        if (res.meta?.requestStatus === "fulfilled") {
            displayToast({ msg: "Updated successfully", type: "success" });
            setLoading(false);
            dispatch(togglePendingStatusModal({ isOpen: false, data: undefined }))
        }
        if (res.meta?.requestStatus === "rejected") {
            displayToast({ msg: "can't update", type: "error" });
            setLoading(false);
        }
    };

    const footer = () => (
        <div className="send-msg p-2">
            <div className="d-flex justify-content-end align-items-center m-0">
                <button
                    className="custom-button"
                    onClick={() => dispatch(togglePendingStatusModal({ isOpen: false, data: undefined }))}
                >
                    Cancel
                </button>
                <button
                    className="common-btn me-0"
                    onClick={handleUpdate}
                    disabled={loading}
                >
                    Update

                    {loading && <Spinner className="ms-2" size="sm" color="light" />}
                </button>

            </div>
        </div>
    );

    return (
        <>

            <Dialog
                onHide={() => dispatch(togglePendingStatusModal({ isOpen: false, data: undefined }))}
                visible={pendingStatusModal?.isOpen}
                draggable={false}
                className="pending-status-modal"
                style={{ width: "700px" }}
                footer={footer}
                header="Update Pending Status"

            >
                <div className="d-flex justify-content-between align-items-center">
                    {statusList.map((val, ind) => {
                        return (
                            <div className="d-flex align-items-center gap-2">
                                <RadioButton
                                    inputId={`status${ind}`}
                                    name="status"
                                    value={val.status_id}
                                    onChange={(e) => setSelectedStatus(e.value)}
                                    checked={selectedStatus === val.status_id}
                                    className="common-radio"
                                />
                                <label htmlFor={`status${ind}`} className="ml-2">{val.status_description}</label>
                            </div>
                        );
                    })}
                </div>
                <div className="d-flex justify-content-start align-items-center gap-4 mt-4">
                    <div className="fw-bold">Follow-Up By:</div>
                    <div className="d-flex gap-3">
                        {roleNamesList.map((val, ind) => {
                            return (
                                <div className="d-flex align-items-center gap-2">
                                    <RadioButton
                                        inputId={`role${ind}`}
                                        name="role"
                                        value={val.role_id}
                                        onChange={(e) => setSelectedRole(e.value)}
                                        checked={selectedRole === val.role_id}
                                        disabled={selectedStatus !== 29}
                                        className="common-radio"
                                    />
                                    <label htmlFor={`role${ind}`} className="ml-2">{val.role_name}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default PendingStatusModal;