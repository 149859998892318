import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utility/api";
import axios from "../utility/api";

export const authfetchAll = createAsyncThunk("authfetchAll", async (userEmail) => {
  try {
    if (userEmail == null) {
      return [];
    }
    const response = await api.get(`/api/ExpiringAuth/expiringAuth`);



    return {
      data: response.data.data,
      isUserPermission: response.data.permission.isUserPermission,
      isUserExpAuthPermission: response.data.permission.isUserExpAuthPermission,
      userRoles: response.data.permission.userRoles
    };
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const getIntakeReportList = createAsyncThunk("getIntakeReportList", async () => {
  try {
    const response = await api.get(`/api/ExpiringAuth/getIntakeReportList`);
    return response;
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});
export const downloadFile = createAsyncThunk("downloadFile", async (fileName) => {
  try {
    const response = await api.get(`/api/ExpiringAuth/download/${fileName}`);
    return response.data.data
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});


export const updateCheckbox = createAsyncThunk(
  "updateCheckbox",
  async ({ callback, dataPayload }) => {
    try {


      const newData = {
        inputjson: dataPayload?.inputjson,
        employee_id: dataPayload?.employee_id,
        flag_status: dataPayload?.flag_status,
        created_by: dataPayload?.created_by,
      };

      const response = await api.post(`/api/ExpiringAuth`, newData);




      if (callback && response?.data?.success) {
        callback(response);
      }

      return {
        inputjson: dataPayload?.inputjson,
        medicine_authorization_id: dataPayload?.medicine_authorization_id,
        employee_id: dataPayload?.employee_id,
        flag_status: dataPayload?.flag_status,
        created_by: dataPayload?.created_by,
        work_status: dataPayload?.work_status,
        work_user: dataPayload?.work_user
      };


    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;

    }
  }
);

export const emailsend = createAsyncThunk(
  "emailsend",
  async ({
    mrn, sentBy, patient_id, medicine_authorization_id, subject, body, ccEmailIds, emailIds
  }) => {

    try {


      var newData = {
        mrn: mrn,
        emailIds: emailIds,
        ccEmailIds: ccEmailIds,
        subject: subject,
        body: body,
        medicine_authorization_id: medicine_authorization_id,
        patient_id: patient_id,
        sentBy: sentBy
      };

      const response = await api.post(`api/ExpiringAuth/SendPhysicianEmail`, newData);

      return {
        mrn: mrn,
        emailIds: emailIds,
        ccEmailIds: ccEmailIds,
        subject: subject,
        body: body,
        medicine_authorization_id: medicine_authorization_id,
        patient_id: patient_id,
        sentBy: sentBy
      };
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
);



export const uploadFileForExpiringAuth = createAsyncThunk(
  "uploadFileForExpiringAuth",
  async (requestData) => {
    try {
      const response = await api.post("/api/ExpiringAuth/UploadFileForExpiringAuth", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);


export const fileDownload = createAsyncThunk(
  "fileDownload",
  async (filename) => {
    try {
      const newData = {
        fileName: filename,
      };
      const response = await axios.post("/api/ExpiringAuth/DownloadFile", newData, {
        responseType: "blob"
      });
      return response;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);

export const toggleEmailModal = createAsyncThunk(
  "toggleEmailModal",
  async ({ isEmailOpen, id, rowData }) => {
    return {
      isOpen: isEmailOpen,
      rowData: rowData,
    };
  }
);

// Create the patient slice
const expiringAuthSlice = createSlice({
  name: "expiringAuth",
  initialState: {
    loading: false,
    modalLoading: false,
    items: [],
    isEmailOpen: false,
    rowData: null,
    isUserExpAuthPermission: false,
    userRoles: [],
    intakeList: []
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(authfetchAll.fulfilled, (state, action) => {
      state.isUserExpAuthPermission = action.payload.isUserExpAuthPermission;
      state.userRoles = action.payload.userRoles
      const newData = action.payload.data?.map((val) => {
        return {
          ...val,
          site_name: val.site_name === null ? "" : val.site_name,
          sales_rep: val.sales_rep === null ? "" : val.sales_rep,
          insuranse_comp: val.insuranse_comp === null ? "" : val.insuranse_comp,
          case_manager: val.case_manager === null ? "" : val.case_manager,
          category: val.category === null ? "" : val.category,
          prescriber_name: val.prescriber_name === null ? "" : val.prescriber_name,

        }
      });
      state.items = newData;
      state.loading = false;
    });
    builder.addCase(authfetchAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(authfetchAll.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getIntakeReportList.fulfilled, (state, action) => {
      state.intakeList = action.payload
    });


    builder.addCase(updateCheckbox.fulfilled, (state, action) => {
      const { medicine_authorization_id, flag_status, employee_id, created_by, work_status, work_user } = action.payload;
      const updatedItems = state.items?.map(item => {
        if (medicine_authorization_id?.includes(item?.medicine_authorization_id)) {
          return {
            ...item,
            flag_status: flag_status,
            employee_id: employee_id,
            created_by: created_by,
            work_status: work_status,
            work_user: work_user,
            is_assign: work_status
          };
        }
        return item;
      });
      state.items = updatedItems;

    });
    builder.addCase(toggleEmailModal.fulfilled, (state, action) => {

      state.isEmailOpen = action.payload.isOpen;
      state.rowData = action.payload.rowData;
    });
    builder.addCase(emailsend.pending, (state, action) => {

      state.isEmailOpen = true;
      state.modalLoading = true;
    });
    builder.addCase(emailsend.fulfilled, (state, action) => {

      state.isEmailOpen = false;
      state.modalLoading = false;
    });

  },
});

export default expiringAuthSlice.reducer;
