import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { taskDetails, taskToggle } from '../../redux/taskSlice';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Tooltip } from 'reactstrap';
import { RadioButton } from 'primereact/radiobutton';
import DateFormatter from '../../components/Filter/DateFormatter';
import { Link, useLocation } from 'react-router-dom';
import { fetchAll, updateData } from '../../redux/patientSlice';
import { Checkbox } from 'primereact/checkbox';
import DateTimeDisplay from '../../components/DateToEST';


const UpdateTaskModal = ({ userName,
  emailname,
  userRole,
  fullname,
  referralSource,
  dob,
  name,
  categories,
  payer,
  prescriberState,
  patientmrn,
  patientState, userEmail, patientfullName }) => {

  const dispatch = useDispatch();
  const { pendingStatusModal, statusDescription, roleNames, taskItem, modalLoading } = useSelector((state) => state.updateTask)
  const taskdetails = useSelector((state) => state.patient);

  const toggle = () => {
    dispatch(taskToggle({ isOpen: false }))
    setError(false)
  }




  const [statusList, setStatusList] = useState([]);
  const [roleNamesList, setRoleNamesList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [nonMandatoryTasks, setNonMandatoryTasks] = useState(0);
  const location = useLocation();
  const path = location.pathname;
  const [isnogo, setIsNogo] = useState(true);
  const [error, setError] = useState(false);
  const [ErrorFolloup, setErrorFolloup] = useState(false);
  const [checkedTasks, setCheckedTasks] = useState(0);
  const [updatetask, setUpdatetask] = useState([]);

  const roleOrder = ["Intake", "Nursing", "Pharmacy "];





  // Sort the roles based on the defined order
  const sortedRoles = useMemo(() => {
    const newArray = [];
    newArray[0] = roleNames[1];
    newArray[1] = roleNames[2];
    newArray[2] = roleNames[0];
    return newArray;
  }, [roleNames]);




  useEffect(() => {
    if (pendingStatusModal?.data?.mrn) {
      dispatch(taskDetails(pendingStatusModal?.data?.mrn));
    }
  }, [pendingStatusModal?.isOpen]);

  useEffect(() => {
    setUpdatetask(taskItem);

  }, [taskItem]);


  const allowedRoles = ["Super User", "Operations", "Intake Manager"];
  const isRoleAllowed = allowedRoles.includes(userRole);

  useEffect(() => {
    setIsNogo(path !== "/no-go");
  }, [path]);

  useEffect(() => {
    const newData = statusDescription?.filter((item) => {
      return item?.status_description === "Pending - Intake" ||
        item?.status_description === "Pending - Nursing" ||
        item?.status_description === "Pending - Pharmacy" ||
        item?.status_description === "Pending - Patient"
    });
    setStatusList(newData);
  }, [statusDescription]);

  useEffect(() => {
    const newData = sortedRoles?.filter((item) => {
      return item?.role_name === "Intake" ||
        item?.role_name === "Pharmacy" ||
        item?.role_name === "Nurse"
    });
    setRoleNamesList(newData);
  }, [sortedRoles]);

  useEffect(() => {
    if (selectedStatus !== 29) {
      setSelectedRole("");
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (pendingStatusModal?.data && pendingStatusModal?.data?.pending_sub_status) {
      const getStatus = statusList?.filter((item) => {
        return item?.status_description === pendingStatusModal?.data?.pending_sub_status
      });
      setSelectedStatus(getStatus[0]?.status_id);
    }
    if (pendingStatusModal?.data && pendingStatusModal?.data?.follow_up_by_role) {
      const getRole = roleNamesList.filter((item) => {
        return item?.role_name === pendingStatusModal?.data?.follow_up_by_role
      });
      setSelectedRole(getRole[0]?.role_id);
    }
  }, [pendingStatusModal?.data]);

  useEffect(() => {
    if (!pendingStatusModal?.isOpen) {
      setSelectedRole("");
      setSelectedStatus("");
    }
  }, [pendingStatusModal?.isOpen]);



  const [demo, setDemo] = useState([]);

  useEffect(() => {

    setDemo(taskItem);
  }, [taskItem]);



  const isDateObject = (value) => value instanceof Date && !isNaN(value);


  const handleUpdate = async () => {
    setLoading(true);

    // Extract the necessary task status values
    const mangecare = demo?.find(item => item.task_id === 17)?.is_current;
    const nutritionReviewChecked = demo?.find(item => item.task_id === 18)?.is_current;
    const prgmMgmtChecked = demo?.find(item => item.task_id === 19)?.is_current;

    const isAnyFollowUpChecked = (selectedStatus === 29 && selectedRole) || mangecare || nutritionReviewChecked || prgmMgmtChecked;


    // Check if selectedStatus is valid
    if (!selectedStatus) {
      setError(true);
      setLoading(false);
      return; // Early return to prevent API call
    } else {
      setError(false);
    }

    // Check for follow-up conditions
    if (!isAnyFollowUpChecked && selectedStatus === 29) {
      setErrorFolloup(true);
      setLoading(false);
      return; // Early return to prevent API call
    } else {
      setErrorFolloup(false);
    }

    try {
      // Make the API call
      const res = await dispatch(updateData({
        patientTasksStatusDTOs: demo,
        email: userEmail,
        manageCareReviewChecked: mangecare,
        nutritionReviewChecked: nutritionReviewChecked,
        prgmMgmtChecked: prgmMgmtChecked,
        name: patientfullName,
        category: categories,
        payer: payer,
        patient_state: patientState,
        prescriber_state: prescriberState,
        patient_sub_status_id: pendingStatusModal.data?.patient_sub_status_id || 0,
        mrn: `${patientmrn}`,
        status_id: selectedStatus,
        follow_up_by_role: selectedRole || null
      }));

      setLoading(false);
      if (res.meta.requestStatus === "fulfilled") {
        toggle();
        dispatch(fetchAll(userEmail));
      }
    } catch (error) {
      console.error("Error updating data:", error);
      setLoading(false);
    }
  };



  const handleChange = (e, task_id) => {

    const apiDataFlag = taskItem?.find(item => item.task_id === task_id)?.is_current;

    if (apiDataFlag) {

      const isChangable =
        userRole === "Super User" ||
        userRole === "Operations" ||
        userRole === "Intake Manager";
      if (isChangable) {
        setDemo((prev) =>
          prev.map((item) =>
            item.task_id === task_id
              ? {
                ...item, is_current: e.checked,
                modified_date: e.checked ? new Date().toISOString() : null,
                modified_by: e.checked ? name : null,
              } : item
          )
        );
      } else {
        return;
      }

    } else {
      setDemo((prev) =>
        prev.map((item) =>
          item.task_id === task_id
            ? {
              ...item, is_current: e.checked,
              modified_date: e.checked ? new Date().toISOString() : null,
              modified_by: e.checked ? name : null,
            } : item
        )
      );
    }
  };

  const checkDisable = (task_id) => {
    const apiDataFlag = taskItem?.find(item => item.task_id === task_id)?.is_current;
    const isChangable = ["Super User", "Operations", "Intake Manager"].includes(userRole);
    if (apiDataFlag && !isChangable) {
      return true; // Disable checkbox
    }
    return false;
  };

  const checkChildDisable = (task_id, childIndex) => {
    const apiDataFlag = taskItem?.find(item => item.task_id === task_id)?.childList[childIndex]?.is_current;
    const isChangable = ["Super User", "Operations", "Intake Manager"].includes(userRole);
    if (apiDataFlag && !isChangable) {
      return true; // Disable checkbox
    }
    return false;
  };

  const checkNADisable = (task_id) => {
    const apiDataFlag = taskItem?.find(item => item.task_id === task_id)?.is_nonmandatory;
    const isChangable = ["Super User", "Operations", "Intake Manager"].includes(userRole);
    if (apiDataFlag && !isChangable) {
      return true; // Disable checkbox
    }
    return false;
  };


  const handleChangeChild = (e, task_id, ind) => {



    const apiDataFlag = taskItem?.find(item => item.task_id === task_id)?.is_current;

    if (apiDataFlag) {

      const isChangable =
        userRole === "Super User" ||
        userRole === "Operations" ||
        userRole === "Intake Manager";
      if (isChangable) {
        setDemo((prevTasks) =>
          prevTasks.map((task) => {
            if (task.task_id === task_id && task.childList) {
              return {
                ...task,
                childList: task.childList.map((child, index) =>
                  index === ind
                    ? {
                      ...child, is_current: e.checked,
                      modified_date: e.checked ? new Date().toISOString() : null,
                      modified_by: e.checked ? name : null,
                    }
                    : child
                ),
              };
            }
            return task;
          })
        );
      } else {
        return;
      }

    } else {
      setDemo((prevTasks) =>
        prevTasks.map((task) => {
          if (task.task_id === task_id && task.childList) {
            return {
              ...task,
              childList: task.childList.map((child, index) =>
                index === ind
                  ? {
                    ...child, is_current: e.checked,
                    modified_date: e.checked ? new Date().toISOString() : null,
                    modified_by: e.checked ? name : null,
                  }
                  : child
              ),
            };
          }
          return task;
        })
      );
    }





  };

  const handleMandetoryChange = (e, task_id) => {

    const apiDataFlag = taskItem?.find(item => item.task_id === task_id)?.is_current;

    if (apiDataFlag) {

      const isChangable =
        userRole === "Super User" ||
        userRole === "Operations" ||
        userRole === "Intake Manager";
      if (isChangable) {
        setDemo((prev) =>
          prev.map((item) =>
            item.task_id === task_id
              ? {
                ...item,
                is_nonmandatory: e.checked,
                modified_date: e.checked ? new Date().toISOString() : null,
                modified_by: e.checked ? name : null,
              }
              : {
                ...item,
                is_current: item.task_id === 14 || item.task_id === 15 || item.task_id === 16 || item.task_id === 7 ? false : item.is_current
              }
          )
        );
      } else {
        return;
      }

    } else {
      setDemo((prev) =>
        prev.map((item) =>
          item.task_id === task_id
            ? {
              ...item,
              is_nonmandatory: e.checked,
              modified_date: e.checked ? new Date().toISOString() : null,
              modified_by: e.checked ? name : null,
            }
            : {
              ...item,
              is_current: item.task_id === 14 || item.task_id === 15 || item.task_id === 16 || item.task_id === 7 ? false : item.is_current
            }
        )
      );
    }
  };





  const handleMandetoryReviewChange = (e, task_id) => {


    const apiDataFlag = taskItem?.find(item => item.task_id === task_id)?.is_current;

    if (apiDataFlag) {

      const isChangable =
        userRole === "Super User" ||
        userRole === "Operations" ||
        userRole === "Intake Manager";
      if (isChangable) {
        setDemo((prev) =>
          prev.map((item) =>
            item.task_id === task_id
              ? {
                ...item,
                is_nonmandatory: e.checked,
                modified_date: e.checked ? new Date().toISOString() : null,
                modified_by: e.checked ? name : null,
              }
              : {
                ...item,
                is_current: item.task_id === 17 || item.task_id === 18 || item.task_id === 19 ? false : item.is_current
              }
          )
        );
      } else {
        return;
      }

    } else {
      setDemo((prev) =>
        prev.map((item) =>
          item.task_id === task_id
            ? {
              ...item,
              is_nonmandatory: e.checked,
              modified_date: e.checked ? new Date().toISOString() : null,
              modified_by: e.checked ? name : null,
            }
            : {
              ...item,
              is_current: item.task_id === 17 || item.task_id === 18 || item.task_id === 19 ? false : item.is_current
            }
        )
      );
    }


  };


  return (
    <>

      <Modal isOpen={pendingStatusModal?.isOpen} toggle={toggle} className='updatetask-modal' centered >
        <ModalHeader toggle={toggle} className='d-flex align-items-start pb-2'>


          <div className='task-details'>
            <p className='m-0'>
              Name : {patientfullName}
            </p>
            <p className='mb-2'>
              DOB: {dob}
            </p>

          </div>
          <h5 className="title-task mb-0"> Next Action By</h5>

        </ModalHeader>
        <ModalBody className='pb-0'>
          <div className="row justify-content-between align-items-center">
            {statusList?.map((val, ind) => {
              return (
                <>
                  <div className="col-md-3 mb-3 d-flex align-items-center">
                    <RadioButton
                      inputId={`status${ind}`}
                      name="status"
                      value={val.status_id}
                      onChange={(e) => {
                        setSelectedStatus(e.value);
                        setError(false);
                      }}
                      checked={selectedStatus === val.status_id}
                      className="common-radio"
                      disabled={!isnogo}
                    />
                    <label htmlFor={`status${ind}`} className="ml-2">{val.status_description}</label>
                  </div>
                </>
              );
            })}
          </div>
          {error && <p className="error-message" style={{ color: 'red' }}>Please select a Next Action By</p>}
          {selectedStatus === 29 && (
            <div className="mb-3">
              <h5 className='title-task'>Follow-Up By:</h5>
              <div className="d-flex gap-3">
                {sortedRoles?.map((val, ind) => (
                  <div className="d-flex align-items-center gap-2" key={val.role_id}>
                    <RadioButton
                      inputId={`role${ind}`}
                      name="role"
                      value={val.role_id}
                      onChange={(e) => {
                        setSelectedRole(e.value);
                        setErrorFolloup(false); // Clear follow-up error
                      }}
                      checked={selectedRole === val.role_id}
                      disabled={selectedStatus !== 29 || !isnogo}
                      className="common-radio"
                    />
                    <label htmlFor={`role${ind}`} className="ml-2">{val.role_name}</label>
                  </div>
                ))}
              </div>
              {ErrorFolloup && <p className="error-message" style={{ color: 'red' }}>Please select at least one follow-up task</p>}
            </div>
          )}
          {/* <TaskList pendingStatusModal={pendingStatusModal} userRole={userRole} /> */}
          <h5 className="title-task">Task:</h5>

          {modalLoading ? (
            <div className="loading-container d-flex align-items-center justify-content-center">
              <Spinner />
            </div>
          ) : (
            <>



              <div className='row m-0'>
                <div className='col-md-4 p-0'>
                  <div className="header text-center header-table">Intake</div>
                  <div className='custom-radio-group mb-2 d-flex align-items-start'>

                    <Checkbox
                      className='custom-radio'
                      inputId="intake_demographics"
                      name="intake_demographics"
                      onChange={(e) => {
                        handleChange(e, 1)
                      }}
                      checked={demo?.find(item => item.task_id === 1)?.is_current}
                      disabled={checkDisable(1) || !isnogo}



                    />
                    <div className='d-flex flex-column ms-2'>
                      <label className=' custom-target-icon d-inline-block' htmlFor="intake_demographics" data-pr-tooltip="Patient has been loaded into CPR+ and Pt contacted"
                        data-pr-position="right">Demographics
                        <Tooltip target=".ms-2" arrow="true" />
                      </label>
                      {demo?.find(item => item.task_id === 1)?.is_current && (
                        <div className='userdetails'>
                          {demo[0]?.modified_by}, <DateTimeDisplay date={demo[0]?.modified_date} />
                        </div>
                      )}

                    </div>
                  </div>
                  <div className='custom-radio-group mb-2 d-flex align-items-start'>

                    <Checkbox
                      className='custom-radio'
                      inputId="benefits_check"
                      name="benefits_check"
                      onChange={(e) => {
                        handleChange(e, 2)
                      }}
                      checked={demo?.find(item => item.task_id === 2)?.is_current}
                      disabled={checkDisable(2) || !isnogo}

                    />
                    <label className='ms-2 custom-target-icon d-inline-block' htmlFor="benefits_check" data-pr-tooltip="Patient has been loaded into CPR+ and Pt contacted"
                      data-pr-position="right">Benefits Check
                      {demo?.find(item => item.task_id === 2)?.is_current && (
                        <div className='userdetails'>
                          {demo[1]?.modified_by} , <DateTimeDisplay date={demo[1]?.modified_date} />
                        </div>
                      )}
                    </label>
                    <Tooltip target=".ms-2" arrow="true" />

                  </div>
                  <div className='custom-radio-group mb-2 d-flex align-items-start'>

                    <Checkbox
                      className='custom-radio'
                      inputId="order_information"
                      name="order_information"
                      onChange={(e) => {
                        handleChange(e, 3)
                      }}
                      checked={demo?.find(item => item.task_id === 3)?.is_current}
                      disabled={checkDisable(3) || !isnogo}

                    />
                    <label className='ms-2' htmlFor="order_information" data-pr-tooltip="Order Information"
                      data-pr-position="right">Order Information

                      {demo?.find(item => item.task_id === 3)?.is_current && (
                        <div className='userdetails'>
                          {demo[2]?.modified_by}, <DateTimeDisplay date={demo[2]?.modified_date} />
                        </div>
                      )}
                    </label>
                    <Tooltip target=".ms-2" arrow="true" />
                  </div>
                  <div className='custom-radio-group mb-2 d-flex align-items-start'>

                    <Checkbox
                      className='custom-radio'
                      inputId="nursing_clinical_review"
                      name="nursing_clinical_review"
                      onChange={(e) => {
                        handleChangeChild(e, 4, 0);
                      }}
                      checked={demo?.find(item => item.task_id === 4)?.childList[0]?.is_current}
                      disabled={checkDisable(4) || !isnogo}

                    />
                    <label className='ms-2' htmlFor="nursing_clinical_review" data-pr-tooltip="Clinical Review"
                      data-pr-position="right">Clinical Review

                      {demo?.find(item => item.task_id === 4)?.childList[0]?.is_current && (
                        <div className='userdetails'>
                          {demo?.find(item => item.task_id === 4)?.childList[0]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 4)?.childList[0]?.modified_date} />
                        </div>
                      )}
                    </label>
                    <Tooltip target=".ms-2" arrow="true" />
                  </div>

                  <div className='custom-radio-group mb-2 d-flex align-items-start'>

                    <Checkbox
                      className='custom-radio'
                      inputId="intake_notification"
                      name="intake_notification"
                      onChange={(e) => {
                        handleChange(e, 6)
                      }}
                      checked={demo?.find(item => item.task_id === 6)?.is_current}
                      disabled={checkDisable(6) || !isnogo}

                    />
                    <label className='ms-2' htmlFor="intake_notification" data-pr-tooltip=">Nursing and Pharmacy Notification"
                      data-pr-position="right">Nursing and Pharmacy Notification

                      {demo?.find(item => item.task_id === 6)?.is_current && (
                        <div className='userdetails '>
                          {demo?.find(item => item.task_id === 6)?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 6)?.modified_date} />
                        </div>
                      )}
                    </label>
                    <Tooltip target=".ms-2" arrow="true" />
                  </div>
                  <div className='custom-radio-group mb-0 d-flex align-itemsa-center'>
                    <div className="section">
                      <h4>Authorizations:</h4>
                      <div className='row'>
                        <div className='col-md-6 col-6 mb-3 d-flex align-items-start'>
                          <Checkbox
                            className='custom-radio'
                            inputId="auth_submitted"
                            name="authorization"
                            value="Submitted"
                            // onChange={handleAuthorizationChange}
                            onChange={(e) => {
                              handleChangeChild(e, 5, 2)
                              handleChangeChild({ checked: false }, 5, 1);
                              handleChangeChild({ checked: false }, 5, 0);
                              handleMandetoryChange({ checked: false }, 5);
                            }}
                            checked={demo?.find(item => item.task_id === 5)?.childList[2]?.is_current}
                            disabled={checkNADisable(5) || checkChildDisable(5, 2) || checkChildDisable(5, 0) || checkChildDisable(5, 1) || !isnogo}

                          />
                          <div className='d-flex flex-column ms-2'>
                            <label htmlFor="auth_submitted" data-pr-tooltip="Submitted"
                              data-pr-position="right">Submitted

                            </label>
                            {demo?.find(item => item.task_id === 5)?.childList[2]?.is_current && (
                              <div className='userdetails sub-userdetails'>
                                {demo?.find(item => item.task_id === 5)?.childList[2]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 5)?.childList[2]?.modified_date} />
                              </div>
                            )}

                          </div>
                          <Tooltip target=".ms-2" arrow="true" />
                        </div>
                        <div className='col-md-6 col-6 mb-3 d-flex align-items-start'>
                          <Checkbox
                            className='custom-radio'
                            inputId="auth_approved"
                            name="authorization"
                            value="Approved"
                            onChange={(e) => {
                              handleChangeChild(e, 5, 0)
                              handleChangeChild({ checked: false }, 5, 1);
                              handleChangeChild({ checked: false }, 5, 2);
                              handleMandetoryChange({ checked: false }, 5);
                            }}
                            checked={demo?.find(item => item.task_id === 5)?.childList[0]?.is_current}
                            // disabled={!isRoleAllowed || !isnogo}
                            disabled={checkNADisable(5) || checkChildDisable(5, 2) || checkChildDisable(5, 0) || checkChildDisable(5, 1) || !isnogo}
                          />
                          <div className='d-flex flex-column ms-2'>
                            <label htmlFor="auth_approved" data-pr-tooltip="Approved"
                              data-pr-position="right">Approved
                            </label>
                            {demo?.find(item => item.task_id === 5)?.childList[0]?.is_current && (
                              <div className='userdetails sub-userdetails'>
                                {demo?.find(item => item.task_id === 5)?.childList[0]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 5)?.childList[0]?.modified_date} />
                              </div>
                            )}
                          </div>
                          <Tooltip target=".ms-2" arrow="true" />
                        </div>
                        <div className='col-md-6 col-6 mb-3 d-flex align-items-start'>

                          <Checkbox
                            className='custom-radio'
                            inputId="auth_denied"
                            name="authorization"
                            value="Denied"
                            onChange={(e) => {
                              handleChangeChild(e, 5, 1)
                              handleChangeChild({ checked: false }, 5, 0);
                              handleChangeChild({ checked: false }, 5, 2);
                              handleMandetoryChange({ checked: false }, 5);
                            }}
                            checked={demo?.find(item => item.task_id === 5)?.childList[1]?.is_current}
                            // disabled={!isRoleAllowed || !isnogo}
                            disabled={checkNADisable(5) || checkChildDisable(5, 2) || checkChildDisable(5, 0) || checkChildDisable(5, 1) || !isnogo}
                          />


                          <div className='d-flex flex-column ms-2'>
                            <label htmlFor="auth_denied" data-pr-tooltip="Denied"
                              data-pr-position="right">Denied
                            </label>
                            {demo?.find(item => item.task_id === 5)?.childList[1]?.is_current && (
                              <div className='userdetails sub-userdetails'>
                                {demo?.find(item => item.task_id === 5)?.childList[1]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 5)?.childList[1]?.modified_date} />
                              </div>
                            )}

                          </div>
                          <Tooltip target=".ms-2" arrow="true" />
                        </div>
                        <div className='col-md-6 col-6 mb-3 d-flex align-items-start'>

                          <Checkbox
                            className='custom-radio'
                            inputId="auth_na"
                            name="authorization"
                            value="N/A"
                            onChange={(e) => {
                              handleMandetoryChange(e, 5)
                              handleChangeChild({ checked: false }, 5, 1)
                              handleChangeChild({ checked: false }, 5, 0);
                              handleChangeChild({ checked: false }, 5, 2);
                            }}
                            checked={demo?.find(item => item.task_id === 5)?.is_nonmandatory}
                            // disabled={!isRoleAllowed || !isnogo}
                            disabled={checkNADisable(5) || checkChildDisable(5, 2) || checkChildDisable(5, 0) || checkChildDisable(5, 1) || !isnogo}
                          />
                          <div className='d-flex flex-column ms-2'>

                            <label htmlFor="auth_na">N/A</label>
                            {demo?.find(item => item.task_id === 5)?.is_nonmandatory && (
                              <div className='userdetails sub-userdetails'>
                                {demo?.find(item => item.task_id === 5)?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 5)?.modified_date} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='custom-radio-group mb-2 d-flex align-items-start'>
                    <div className="section">
                      <h4>Review:</h4>
                      <div className='row'>
                        <div className='col-md-6 col-6 pe-0 mb-3 d-flex align-items-start'>
                          <Checkbox
                            className='custom-radio'
                            inputId="mange_care"
                            name="mangecare"
                            value="mangecare"
                            // onChange={handleAuthorizationChange}
                            onChange={(e) => {
                              handleChangeChild(e, 9, 0);
                              handleChangeChild({ checked: false }, 9, 1);
                              handleChangeChild({ checked: false }, 9, 2);
                              handleMandetoryReviewChange({ checked: false }, 9);
                            }}
                            checked={demo?.find(item => item.task_id === 9)?.childList[0]?.is_current}
                            // disabled={!isRoleAllowed || !isnogo}
                            disabled={checkNADisable(9) || checkChildDisable(9, 2) || checkChildDisable(9, 1) || checkChildDisable(9, 0) || !isnogo}
                          />
                          <div className='d-flex flex-column'>
                            <label className='ms-2' htmlFor="mange_care" data-pr-tooltip="mangecare"
                              data-pr-position="right">Managed Care

                            </label>
                            {demo?.find(item => item.task_id === 9)?.childList[0]?.is_current && (
                              <div className='userdetails sub-userdetails ms-2'>
                                {demo?.find(item => item.task_id === 9)?.childList[0]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 9)?.childList[0]?.modified_date} />
                              </div>
                            )}

                          </div>
                          <Tooltip target=".ms-2" arrow="true" />
                        </div>
                        <div className='col-md-6 col-6 pe-0 mb-3 d-flex align-items-start'>
                          <Checkbox
                            className='custom-radio'
                            inputId="nutrition"
                            name="Nutrition"
                            value="Nutrition"
                            onChange={(e) => {
                              handleChangeChild(e, 9, 1);
                              handleChangeChild({ checked: false }, 9, 0);
                              handleChangeChild({ checked: false }, 9, 2);
                              handleMandetoryReviewChange({ checked: false }, 9);
                            }}
                            checked={demo?.find(item => item.task_id === 9)?.childList[1]?.is_current}
                            disabled={checkNADisable(9) || checkChildDisable(9, 2) || checkChildDisable(9, 1) || checkChildDisable(9, 0) || !isnogo}
                          />
                          <div className='d-flex flex-column '>
                            <label className='ms-2' htmlFor="nutrition" data-pr-tooltip="Nutrition"
                              data-pr-position="right">Nutrition

                            </label>
                            {demo?.find(item => item.task_id === 9)?.childList[1]?.is_current && (
                              <div className='userdetails sub-userdetails ms-2'>
                                {demo?.find(item => item.task_id === 9)?.childList[1]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 9)?.childList[1]?.modified_date} />
                              </div>
                            )}

                          </div>
                          <Tooltip target=".ms-2" arrow="true" />
                        </div>
                        <div className='col-md-6 col-6 pe-0  mb-md-0 mb-3 d-flex'>

                          <Checkbox
                            className='custom-radio'
                            inputId="program_mgmt"
                            name="ProgramMgmt"
                            value="ProgramMgmt"
                            onChange={(e) => {
                              handleChangeChild(e, 9, 2);
                              handleChangeChild({ checked: false }, 9, 0);
                              handleChangeChild({ checked: false }, 9, 1);
                              handleMandetoryReviewChange({ checked: false }, 9);
                            }}
                            checked={demo?.find(item => item.task_id === 9)?.childList[2]?.is_current}
                            disabled={checkNADisable(9) || checkChildDisable(9, 2) || checkChildDisable(9, 1) || checkChildDisable(9, 0) || !isnogo}
                          />
                          <div className='d-flex flex-column '>
                            <label className='ms-2' htmlFor="program_mgmt" data-pr-tooltip="ProgramMgmt"
                              data-pr-position="right">Program Mgmt
                            </label>
                            {demo?.find(item => item.task_id === 9)?.childList[2]?.is_current && (
                              <div className='userdetails sub-userdetails ms-2'>
                                {demo?.find(item => item.task_id === 9)?.childList[2]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 9)?.childList[2]?.modified_date} />
                              </div>
                            )}

                          </div>
                          <Tooltip target=".ms-2" arrow="true" />
                        </div>
                        <div className='col-md-6 col-6 pe-0 d-flex'>

                          <Checkbox
                            className='custom-radio'
                            inputId="review"
                            name="review"
                            value="N/A"
                            onChange={(e) => {
                              handleMandetoryReviewChange(e, 9);
                              handleChangeChild({ checked: false }, 9, 2);
                              handleChangeChild({ checked: false }, 9, 0);
                              handleChangeChild({ checked: false }, 9, 1);
                            }}
                            checked={demo?.find(item => item.task_id === 9)?.is_nonmandatory}
                            disabled={checkNADisable(9) || checkChildDisable(9, 2) || checkChildDisable(9, 1) || checkChildDisable(9, 0) || !isnogo}
                          />
                          <div className='d-flex flex-column ms-2'>
                            <label className='ms-2' htmlFor="review">N/A


                            </label>
                            {demo?.find(item => item.task_id === 9)?.is_nonmandatory && (
                              <div className='userdetails sub-userdetails ms-2'>
                                {demo?.find(item => item.task_id === 9)?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 9)?.modified_date} />
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className='col-md-4 p-0'>
                  <div className="header text-center header-table">Nursing

                  </div>
                  <div className='custom-radio-group mb-2 d-flex align-items-start'>
                    <Checkbox
                      className='custom-radio'
                      inputId="intake_clinical_review"
                      name="intake_clinical_review"
                      onChange={(e) => {
                        handleChangeChild(e, 4, 1)
                      }}
                      checked={demo?.find(item => item.task_id === 4)?.childList[1]?.is_current}
                      disabled={checkDisable(4) || !isnogo}

                    />
                    <label className='ms-2' htmlFor="intake_clinical_review" data-pr-tooltip="Clinical Review"
                      data-pr-position="right">Clinical Review

                      {demo?.find(item => item.task_id === 4)?.childList[1]?.is_current && (
                        <div className='userdetails'>
                          {demo?.find(item => item.task_id === 4)?.childList[1]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 4)?.childList[1]?.modified_date} />
                        </div>
                      )}
                    </label>
                    <Tooltip target=".ms-2" arrow="true" />

                  </div>
                  <div className='d-flex'>

                    <div className='custom-radio-group mb-2 d-flex align-items-start'>
                      <Checkbox
                        className='custom-radio'
                        inputId="nursing_scheduling"
                        name="nursing_scheduling"
                        onChange={(e) => {
                          handleMandetoryChange({ checked: false }, 7)
                          handleChange(e, 7);
                        }}
                        checked={demo?.find(item => item.task_id === 7)?.is_current}
                        disabled={checkDisable(7) || !isnogo}

                      />
                      <label className='ms-2 d-flex flex-column' htmlFor="nursing_scheduling" data-pr-tooltip="Nursing Scheduling"
                        data-pr-position="right">Nursing Scheduling
                        {demo?.find(item => item.task_id === 7)?.is_current && (
                          <div className='userdetails  intake-sub-userdetails  '>
                            {demo?.find(item => item.task_id === 7)?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 7)?.modified_date} />
                          </div>
                        )}

                      </label>
                      <Tooltip target=".ms-2" arrow="true" />

                    </div>
                    <div className='custom-radio-group mb-2 d-flex align-items-start ms-2'>
                      <Checkbox
                        className='custom-radio'
                        inputId="nursing_scheduling1"
                        name="nursing_scheduling1"
                        onChange={(e) => {
                          handleChange({ checked: false }, 7)
                          handleMandetoryChange(e, 7);
                        }}
                        checked={demo?.find(item => item.task_id === 7)?.is_nonmandatory}
                        disabled={checkDisable(7) || !isnogo}

                      />
                      <label className='ms-2 d-flex flex-column' htmlFor="nursing_scheduling1" data-pr-tooltip="Nursing Scheduling"
                        data-pr-position="right">N/A

                        {demo?.find(item => item.task_id === 7)?.is_nonmandatory && (
                          <div className='userdetails  intake-sub-userdetails '>
                            {demo?.find(item => item.task_id === 7)?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 7)?.modified_date} />
                          </div>
                        )}
                      </label>
                      <Tooltip target=".ms-2" arrow="true" />

                    </div>
                  </div>
                </div>
                <div className='col-md-4 p-0'>
                  <div className="header text-center header-table">Pharmacy</div>
                  <div className='custom-radio-group mb-2 d-flex align-items-start'>
                    <Checkbox
                      className='custom-radio'
                      inputId="pharmacy_clinical_review"
                      name="pharmacy_clinical_review"
                      onChange={(e) => {
                        handleChangeChild(e, 4, 2)
                      }}
                      checked={demo?.find(item => item.task_id === 4)?.childList[2]?.is_current}
                      disabled={checkDisable(4) || !isnogo}


                    />
                    <label className='ms-2' htmlFor="pharmacy_clinical_review" data-pr-tooltip="Clinical Review"
                      data-pr-position="right">Clinical Review

                      {demo?.find(item => item.task_id === 4)?.childList[2]?.is_current && (
                        <div className='userdetails'>
                          {demo?.find(item => item.task_id === 4)?.childList[2]?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 4)?.childList[2]?.modified_date} />
                        </div>
                      )}
                    </label>
                    <Tooltip target=".ms-2" arrow="true" />

                  </div>
                  <div className='custom-radio-group mb-2 d-flex align-items-start'>
                    <Checkbox
                      className='custom-radio'
                      inputId="pharmacy_delivery_schedule"
                      name="pharmacy_delivery_schedule"
                      onChange={(e) => {
                        handleChange(e, 8)
                      }}
                      checked={demo?.find(item => item.task_id === 8)?.is_current}
                      disabled={checkDisable(8) || !isnogo}


                    />
                    <label className='ms-2' htmlFor="pharmacy_delivery_schedule" data-pr-tooltip="Pharmacy Delivery Schedule"
                      data-pr-position="right">Pharmacy Delivery Schedule

                      {demo?.find(item => item.task_id === 8)?.is_current && (
                        <div className='userdetails'>
                          {demo?.find(item => item.task_id === 8)?.modified_by}, <DateTimeDisplay date={demo?.find(item => item.task_id === 8)?.modified_date} />
                        </div>
                      )}
                    </label>

                    <Tooltip target=".ms-2" arrow="true" />
                  </div>
                </div>
              </div>
            </>
          )}


        </ModalBody>
        <ModalFooter className='d-flex align-items-center justify-content-between'>

          <div className="referral">
            <p className='mb-0'>
              Referral Date : <DateFormatter date={pendingStatusModal?.data?.reference_date} />
            </p>

            <p className='mb-0'>Referral Source : {pendingStatusModal?.data?.referral_source}</p>
          </div>




          <div className="d-flex justify-content-end align-items-center m-0">


            {isnogo && (
              <>
                {(userEmail) && (

                  <Link
                    to={`https://lively-bay-0c3139c0f.5.azurestaticapps.net/create-claim/${patientmrn}/createClaim`}
                    className="common-btn me-2"
                    style={{ textDecoration: "none" }}
                  >
                    Check Eligibility
                  </Link>
                )}
                <button
                  className="common-btn me-2"
                  onClick={handleUpdate}
                  disabled={loading}
                >
                  Submit

                  {loading && <Spinner className="ms-2" size="sm" color="light" />}
                </button>
              </>

            )}
            <button
              className="custom-button"
              onClick={() => dispatch(taskToggle({ isOpen: false, data: undefined }))}
            >
              Cancel
            </button>

          </div>



        </ModalFooter>
      </Modal>
    </>
  )
}

export default UpdateTaskModal