import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer';
import { useDispatch, useSelector } from 'react-redux';
import { pdfModal } from '../../redux/patientSlice';


const ModalFullscreenExample = ({ foldername, filename, secondfolder }) => {

    const dispatch = useDispatch();
    const { isPdfOpen } = useSelector(state => state.patient);
    const [listenForEnterKey, setListenForEnterKey] = useState(false);


    const LICENSE_KEY = 'Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlccXRdRGleU0B2Vko=';

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlccXRdRGleU0B2Vko=') {
                dispatch(pdfModal({ isOpen: true }));
            }
        };

        if (listenForEnterKey) {
            document.addEventListener('keydown', handleKeyPress);
        } else {
            document.removeEventListener('keydown', handleKeyPress);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [listenForEnterKey, dispatch]);

    const togglePdf = () => {
        dispatch(pdfModal({ isOpen: !isPdfOpen }));
        setListenForEnterKey(!listenForEnterKey);
    };



    return (
        <div>
            <Modal isOpen={isPdfOpen} toggle={togglePdf} centered size="xxl">
                <ModalHeader toggle={togglePdf}></ModalHeader>
                <PdfViewerComponent
                    id="container"
                    documentPath={`https://prod-14.westus.logic.azure.com/workflows/21c2d3261b824284996e579bb153e176/triggers/manual/paths/invoke/Folder1/${foldername}/FileName/${filename}/Folder2/${secondfolder}?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=80ueyLsDlS2QQHBjjnmOf3tXxnstnK7s9oosuu0tmxI`}

                    resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
                    licenseKey={LICENSE_KEY}
                >
                </PdfViewerComponent>
                <ModalFooter>
                    <Button color="secondary" onClick={togglePdf}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalFullscreenExample;
