import { configureStore } from '@reduxjs/toolkit'
import patientSlice from './patientSlice';
import expiringauthSlice from './expiringauthSlice';
import physicianSlice from './physicianSlice';
import referralsSlice from './referralsSlice';
import permissionSlice from './permissionSlice';
import updateTask from './taskSlice';
import addNoteSlice from './addNoteSlice';
import advancedFilterReducer  from './advancedFilterSlice'
import customFilterReducer from './customFilterSlice'
import addtopatientchartReducer from  "./addtopatientchartslice"



export const store = configureStore({
  reducer: {
    patient: patientSlice,
    expiringAuth: expiringauthSlice,
    physician: physicianSlice,
    referral: referralsSlice,
    permissions: permissionSlice,
    note: addNoteSlice,
    updateTask: updateTask,
    advancedFilter: advancedFilterReducer,
    customFilter: customFilterReducer,
    addtopatientchart: addtopatientchartReducer

  },
})