import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DateFormatter from './DateFormatter';
import { Spinner } from 'reactstrap';

const ReferralDataTable = ({
  data,
  loading,
  onSelectionChange,
  firstNameBodyTemplate,
  customHeader,
  filters,
  CreateMrn,
  documentModal
}) => {
  const pageOptions = [5, 10, 25, 50, 75, 100, 125];


  return (
    <div>
      <DataTable
        scrollable scrollHeight="645px"
        value={data}
        paginator
        rowsPerPageOptions={pageOptions}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rows={10}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        loading={loading}
        filters={filters}
        onSelectionChange={onSelectionChange}
        globalFilterFields={["first_name", "last_name", "full_name"]}
        emptyMessage="No Records found."
      >
        <Column field="mrn" sortable header="MRN#" />
        <Column
          field="first_name"
          sortable
          header="First Name"
          body={firstNameBodyTemplate}
        />
        <Column
          className="custom-width"
          field="last_name"
          sortable
          header="Last Name"
        />
        <Column
          field="date_of_birth"
          sortable
          body={(rowData) => <DateFormatter date={rowData.date_of_birth} />}
          header="Date of Birth"
          filterField="date_of_birth"
          dataType="date"
        />
        <Column
          field="reference_date"
          sortable
          body={(rowData) => <DateFormatter date={rowData.reference_date} />}
          header="Reference Date"
          filterField="reference_date"
          dataType="date"
        />
        <Column
          field="prescriber"
          sortable
          // body={(rowData) => <DateFormatter date={rowData.reference_date} />}
          header="Prescriber"
        // filterField="reference_date"
        // dataType="date"
        />
        {/* <Column
          field="nursing_visite_date"
          sortable
          body={(rowData) => <DateFormatter date={rowData.nursing_visite_date} />}
          header="Nursing Visit"
          filterField="nursing_visite_date"
          dataType="date"
        />
        <Column field="category" sortable header="Category" />
        <Column field="site" sortable header="Site" /> */}
        <Column header="Documents" body={documentModal} />
        <Column body={CreateMrn} modal={CreateMrn} />
        <Column header={customHeader} />
      </DataTable>
    </div>
  );
};

export default ReferralDataTable;