import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig, msalInstance } from "./authconfig";
import { BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";

const AuthContext = React.createContext({
    isAuthenticated: false,
    signIn: () => { },
    signOut: () => { },
    acquireToken: () => { },
    isInProgress: false,
});

export async function signIn() {
    await msalInstance.loginRedirect(loginRequest).catch(e => {
        console.log(e);
    });
}

export async function signOut() {
    await msalInstance.logoutRedirect();
}

export async function acquireToken() {
    try {
        const response = await msalInstance.acquireTokenSilent(loginRequest);

        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            const response = await msalInstance.acquireTokenPopup(loginRequest);
            return response.accessToken;
        } else if (error instanceof BrowserAuthError) {
            return null;
        } else {
            console.error(error);
        }
    }
}

function AuthHandler({ children }) {
    const { instance, inProgress } = useMsal();
    const currentAccount = instance.getActiveAccount();
    const isAuthenticated = useIsAuthenticated();

    const value = useMemo(() => ({
        signIn, signOut, acquireToken, isAuthenticated, isInProgress: inProgress != 'none',
        account: currentAccount,
    }), [isAuthenticated, inProgress])

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default function AuthProvider({ children }) {
    const [msalInitDone, setMsalInitDone] = useState(false);

    const initializeMsal = async () => {
        await msalInstance.initialize()
        setMsalInitDone(true)
    }

    useEffect(() => {
        initializeMsal();
    }, []);

    if (!msalInitDone) {
        return <></>
    }
    return (
        <MsalProvider instance={msalInstance}>
            <AuthHandler>{children}</AuthHandler>
        </MsalProvider>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}