// components/DateTimeDisplay.js
import React from 'react';

const DateTimeDisplay = ({ date }) => {
    const formatDateToEST = (date) => {
        const options = {
            timeZone: 'America/New_York',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
    };

    return (
        <div className="datetime-display">
            {formatDateToEST(date)}
        </div>
    );
};

export default DateTimeDisplay;
