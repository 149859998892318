import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ExpiringAuthsDataTable = ({
  data,

  filters,
  loading,
  selectedCustomers,
  onSelectionChange,
  mrnBodyTemplate,
  firstNameBodyTemplate,
  expiryDateBodyTemplate,
  uploadBodyTemplate,
  customHeader,
  handleSort,
  days,
  nextfill,
  dateRangeFilter,
  EmailModal,
  assignCheckbox,
  isAssign,
  orderBody,

}) => {
  const pageOptions = [5, 10, 15, 25, 50, 75, 100];

  return (
    <DataTable
      scrollable scrollHeight="600px"
      value={data}
      paginator
      rowsPerPageOptions={pageOptions}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rows={15}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      filters={filters}
      loading={loading}
      selection={selectedCustomers}
      onSelectionChange={onSelectionChange}
      globalFilterFields={["first_name", "last_name", "full_name"]}
      emptyMessage="No Records found."
    >
      {isAssign === true ? <Column header="Assign" body={assignCheckbox} /> : null}

      <Column field="mrn" sortable header="MRN#" body={mrnBodyTemplate} />
      <Column
        field="first_name"
        sortable
        header="First Name"
        body={firstNameBodyTemplate}
        filterMatchMode="custom_authorization_stop_date"
      />
      <Column
        className="custom-width"
        field="last_name"
        sortable
        header="Last Name"
      />
      <Column body={uploadBodyTemplate} />

      <Column field="prescriber_name" sortable header="Prescriber" />

      <Column
        field="authorization_stop_date"
        sortable
        header="Exp.Date"
        filterField="authorization_stop_date"
        // dataType="date"
        body={expiryDateBodyTemplate}
        filterElement={dateRangeFilter}
        filterMatchMode="custom"
      />
      <Column
        field="remain_days"
        sortable
        header="Expiring in"
        body={days}
        sortFunction={handleSort}
      />
      <Column
        field="nextfill"
        sortable
        header="Next Fill"
        filterField="nextfill"
        dataType="date"
        body={nextfill}
      />

      <Column field="order" sortable header="Order" className="my-custom-class" body={orderBody} />
      <Column field="hcpc" sortable header="HCPC" />
      <Column field="category" sortable header="Category" />
      <Column field="site_name" sortable header="Site" />
      <Column field="sales_rep" header="Sales Representative" sortable />

      <Column header={customHeader} />
      {/* <Column
        body={EmailModal}
        modal={EmailModal}
      ></Column> */}
    </DataTable>
  );
};

export default ExpiringAuthsDataTable;
