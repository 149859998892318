import React from "react";
import { Col, Row } from "reactstrap";
import "../../assets/css/filter.css";

function Filterdata({
  Mrnsearch,
  FirstNamesearch,
  dateRangeFilter,
  renderAdvancedFilters,
  // assignFilter
}) {
  return (
    <>
      <div className="custom-form">
        <Row>
          <Col lg="auto"   xl="3" md="6" xxl="3" className="mb-xxl-1 mb-xl-2 mb-xl-0 mb-lg-2 mb-2 pe-1">
            {Mrnsearch}
          </Col>
          <Col xl="3" lg="4" xxl="3" md="6" className="mb-xxl-1 mb-xl-2 mb-xl-0 mb-lg-2 mb-2 px-1">
            {FirstNamesearch}
          </Col>

          {dateRangeFilter}

          <Col
            lg="auto"
            md="4"
            className="mb-xxl-1 mb-xl-2 mb-lg-0 mb-md-2 mb-0 px-1"
          >
            {renderAdvancedFilters}

          </Col>
          {/* <Col
            lg="auto"
            md="4"
            className="mb-xxl-1 mb-xl-2 mb-lg-0 mb-md-2 mb-0"
          >
            {assignFilter}

          </Col> */}
          {/* <Col lg="auto" md="4" className="d-flex justify-content-end ms-auto">
            {nogobutton}
          </Col> */}
        </Row>
      </div>
    </>
  );
}

export default Filterdata;
